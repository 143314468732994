// Модуль для контроля серии устройства (Для отображения или скрытия вкладки "Моющих средств").

export default {
  actions: {
    setSeriesDevice({commit}, seriesDevice) {
      commit("updateSeriesDevice", seriesDevice);
    },
  },
  mutations: {
    updateSeriesDevice(state, seriesDevice) {
      state.seriesDevice = seriesDevice;
    },
  },
  state:{
    seriesDevice: null,
  },
  getters: {
    getSeriesDevice: (state) => state.seriesDevice, // активное устройство
  },
};
