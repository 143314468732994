import axios from "axios";

//--> AuthComp
//--> SideBarComp
// return {
//   "user_id": Number,
//   "username": "String",
//   "is_admin": Boolian,
//   "role_id": null/ string
// }
export const checkUserMe = async () => {
  const response = await axios.get("user/me");
  return response;
};

//newsNotifications (Vuex module)
// Запрос новостей/обновлений
export const fetchUnreadedNews = async () => {
  return await axios.get(`/user/news/unreaded`);
};

//MarkDown component
// Сделать новость прочитанной
export const setReadNews = async (news_id) => {
  const response = await axios.post(`/user/news/read`, { news_id });
  return response;
};

// ArchiveComp component
// Сделать новость прочитанной
export const fetchAllNews = async () => {
  const response = await axios.get(`/user/news/all`);
  return response;
};

//--> PopapAllNews component
// Получаеми все новости
// return [{},{}...{}]
export const getAllNews = async () => {
  const response = await axios.get("/user/news/unreaded");
  return response.data;
};

//--> PopapAllNews component
// Отправляем в архив прочитанную новость
// param newsId
export const userNewsRead = async (news_id) => {
  await axios.post(`/user/news/read`, {
    news_id,
  });
};

//--> RegisterComp
export const Registration = async (userData) => {
  const response = await axios.post("auth/register", userData);
  return response;
};

//--> AuthComp
export const LogIn = async (userData) => {
  const response = await axios.post("auth/jwt/login", userData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      // "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Origin": "*",
    },
    withCredentials: true,
  });
  return response;
};

export const LogOut = async () => {
  const response = await axios.post("auth/jwt/logout");
  return response;
};

//--> RecoveryPassword
//param: current user email
export const forgotPassword = async (email) => {
  const response = axios.post("/auth/forgot-password", { email });
  return response;
};

//--> RestorePassword
//param: current user email
export const resetPassword = async (token, password) => {
  const response = axios.post("/auth/reset-password", {
    token,
    password,
  });
  return response;
};
// --> Chart_Line component
// функция для формирования данных для графика (из Chart_Line)
// @param {number} times - unix time,
// @param {number} values - значения для графика,
// @return [{number}, {number}] - сформированный массив данных
export const conversionData = (times, values) => {
  // console.log(times.length);
  let result = [];
  let getTime = times;
  let getData = values;
  for (let i = 0; i < times.length; i++) {
    let item = [];
    item.push(getTime[i] + 1000 * 60 * 180);
    item.push(getData[i]);
    result.push(item);
  }
  return result;
};
// --> Chart_Line component
// Загрузка данных для графика.
// @param {number} dash_Id - id графика,
// @param {number} timeStart -начало интервала,
// @param {number} timeEnd конец интервала
// @return [{number}, {number}]
// !!!!!!!!!!!!!НОВЙ ВАРИАНТ!!!!!!!!!!!!!!!!!!!!
export const getMetricsForDash = async (dash_Id, timeStart, timeEnd) => {
  const response = await axios.post(`dashboard/graphs/${dash_Id}`, {
    start_dt: timeStart,
    end_dt: timeEnd,
  });
  return response;
};

//-->
// Добавление графика
// param group_id, device_id, Par_name, interval - интервал обновления графика в сек.
export const addGraph = async (
  group_id,
  device_id,
  description,
  Par_name,
  interval
) => {
  await axios.post("/dashboard/graphs/add", {
    group_id,
    device_id,
    description,
    Par_name,
    interval,
  });
};

//--> AddGroupTemplate
//Создаем новую группу для графиков
export const createNewGroup = async (name, description) => {
  const response = await axios.post("/dashboard/groups/add", {
    name: name,
    description: description,
  });
  return response;
};

//--> GraphItemComp
//--> EditGraphTemplate
//Редактирование графика
// PUT
export const updateCurrentGraph = async (
  Dash_id,
  group_id,
  Par_name,
  description,
  interval
) => {
  await axios.put(`/dashboard/graphs/${Dash_id}`, {
    group_id,
    Par_name, //curr///
    description, //--Текущая скорость
    interval,
  });
};

//-->ChartsAll component
// Получаем все группы.
// return [{},{}, ... {}]
export const getGroupsList = async () => {
  const response = await axios.get("dashboard/groups/list");
  return response;
};

//-->ChartsAll component
// Удаляем график
// param graph ID
export const delGraph = async (graphId) => {
  await axios.delete(`/dashboard/graphs/del/${graphId}`);
};

//(-->ChartsAll component)
//Получаем все графики.
//return [{},{}, ... {}]
export const getDashboardsList = async () => {
  const allGraph = await axios.get("/dashboard/get");
  return allGraph;
};

//--> AddGraphTemplate
// Получаем список устройств
export const getDevicesList = async () => {
  const response = await axios.get("/devices/all");
  return response;
};

//--> CalendarApp
// Получаем историю программ устройства.
export const fetchProgramHistory = async (device_id, start_dt, end_dt) => {
  const response = await axios.post(`/devices/program_history`, {
    device_id,
    start_dt,
    end_dt,
  });
  return response;
};

//--> SettingsTab
// Схема моющих средств
//device_id - id устройства
export const fetchCleanersSchema = async (device_id) => {
  const response = await axios.post(`/devices/cleaners_schema`, {
    device_id,
  });
  return response;
};

//--> EditSchemaTemplate
// Типы моющих средств
//device_id - id устройства
export const fetchCleanerTypes = async () => {
  const response = await axios.post(`/devices/cleaners_types`);
  return response;
};

//--> AddDevicesTemplate
// Бобавляем устройство
export const addDevice = async (org_id, u_device_name, code1, code2, code3) => {
  await axios.post("devices/add", {
    org_id,
    u_device_name,
    code1,
    code2,
    code3,
  });
};

//--> SettingsTab
//Измсеняем имя устройства
//deviceId - id устройства
//newDeviceName - новое имя устройства
export const updateDeviceName = async (device_id, newDeviceName) => {
  const response = await axios.put(`/devices/update`, {
    device_id,
    u_device_name: newDeviceName,
  });
  return response;
};

export const addError = async (name, Err_D500, description) => {
  await axios.post("/adm/err/add", {
    name,
    Err_D500,
    description,
  });
};

//-->ServicesTab
// Порог ТО
// param - deviceId
export const getThresholdMaintenance = async (device_id) => {
  if (device_id) {
    const response = await axios.post(`/devices/maintenance`, {
      device_id,
    });
    return response;
  }
};

//-->ServicesTab
// получаем текущие данные ТО
// param - deviceId
export const getCurrentMaintenance = async (device_id) => {
  if (device_id) {
    const response = await axios.post(`/devices/maintenance_counter`, {
      device_id,
    });
    return response;
  }
};

export const getMetricsStepStart = async (deviceId, step) => {
  if (deviceId) {
    const result = await axios.post(`/queries/first_by_interval/${deviceId}`, {
      interval: step,
    });
    return result;
  } else {
    return;
  }
};

//-->MetricBox1
// Получаем устройство по ID
// param: deviceId
export const getDevice = async (device_id) => {
  const response = axios.post(`/devices/id`, {
    device_id,
  });
  return response;
};

//-->MetricsBox1
//-->MetricsBox_2
// получаем данные начала интервала

//--> PopapAdminAdd_News
//param "string"
//return 200 "Ok"
export const addNewNews = async (text) => {
  const response = await axios.post("/adm/news/add", { text });
  return response;
};

//DEPRECATED
//--> ErrorsBoxComponent
//return Array [{},{},...{}]

export const getInfoDetailList = async () => {
  const response = await axios.get("/dashboard/todayinfodetail");
  return response;
};

export const getInfoErrorList = async () => {
  const response = await axios.get("/info/error_list");
  return response;
};

//--> SetttingsComponent
//--> AdminParameters_Component
// return Array [{},{}, ... {}]
export const getParametersListInfo = async () => {
  const response = await axios.get(`/info/parameter_list`);
  return response.data;
};

//--> SetttingsComponent
//--> ProfileComponent
//Получаем данные пользователя
//return Array [{...}]
export const getUserProfile = async () => {
  const response = await axios.get("/user/profile");
  return response.data[0];
};

//-->ProfileComp
// Обновление параметров пользователя
export const updateUserParams = async (paramId, newParam) => {
  axios.put(`/user/parameters/update/${paramId}`, newParam);
};

//--> PanelCounterComp
//Получение данных за текущие сутки(Панель)
export const getTodayInfo = async () => {
  const response = await axios.get("/dashboard/todayinfo");
  return response;
};

//--> AnalyticsComp
// return: Object object
export const getInfoDetail = async (org_id, device_id, start_dt, end_dt) => {
  const response = await axios.post(`/business/organisation/${org_id}/info`, {
    device_id,
    start_dt,
    end_dt,
  });
  return response;
};

//--> AddDevicesTemplate
//--> GraphInteractive
//--> CalendarApp
// Получаем список организаций
// return [{},{}, ... {}]
export const getOrganizationList = async () => {
  const response = await axios.get("/organisations/all");
  return response;
};

//--> ProfileComp
//Получаем список участников для текущей организации
//param - orgId
//return [{...},...,{...}]
export const getStaff = async (org_id) => {
  const { data } = await axios.post(`/organisations/staff`, {
    org_id,
  });
  return data;
};

//--> AddOrganizationTemplate
//Добавить организацию
//return 200 OK
export const addOrganization = async (name, description) => {
  const { data } = await axios.post("/organisations/add", {
    name,
    description,
  });
  return data;
};

//--> AddEmloyeeTemplate
//Добавляем сотрудника
// userId
// "email": "string",
// "Analytic_access": true,
// "Dashboard_access": true,
// "Service_access": true
//return [{...},...,{...}]
export const addEmployee = async (
  org_id,
  email,
  analytic,
  dashboard,
  service
) => {
  const response = await axios.post(`/organisations/invite`, {
    org_id,
    email: email,
    Analytic_access: analytic,
    Dashboard_access: dashboard,
    Service_access: service,
  });
  return response;
};

//--> SettingsTab
//Удалить устройство
// org_id, device_id
export const deleteDevice = async (org_id, device_id) => {
  const { data } = await axios.delete("/organisations/devices/delete", {
    data: {
      org_id,
      device_id,
    }
  });
  return data;
};

//--> SideBarComp
//Получаем список уведомлений
//return [{...},...,{...}]
export const getInvitations = async () => {
  const { data } = await axios.get("/user/invitations");
  return data;
};

//--> ProfileComp
//Подтверждаем или отклоняем приглашение в организацию сотрудника
//return [???]
export const answerSend = async (inv_id, answer) => {
  const response = await axios.post(`/user/invitations/resp`, {
    inv_id,
    answer,
  });
  return response;
};

//--> GraphInteractive
//--> AddGraphTemplate
//Получаем список параметров устройств
//device_id - id устройства
//return [{...},...,{...}]
export const getDeviceParams = async (device_id) => {
  const { data } = await axios.post(`/devices/parameters`, {
    device_id,
  });
  return data;
};

//--> DevicesInfo
//return Array [{},{},...{}]
export const getErrorList = async (device_id) => {
  const response = await axios.post(`/devices/errs`, {
    device_id,
  });
  return response;
};

//--> ErrorsTab
//Делаем ошибку прочитанной
export const setErrorIsView = async (err_id) => {
  const response = await axios.post(`/devices/errs/view`, {
    err_id,
  });
  return response;
};

//-->SettingsTab
// Получаем версию прошивки по ID
// param: device_id
export const getProgramVersion = async (device_id) => {
  const response = await axios.post(`/devices/program_version`, {
    device_id,
  });
  return response;
};

//--> GraphInteractive
// param: deviceId, paramsString,  StartInterval, EndInterval
// return: Object object
export const getInteractiveGraph = async (
  device_id,
  paramsString,
  timeStart,
  timeEnd
) => {
  const response = await axios.post(`/devices/graph`, {
    device_id,
    registers: paramsString,
    start_dt: timeStart,
    end_dt: timeEnd,
  });
  return response.data.data.result;
};

//--> DeviceComp2
//return [{...},...,{...}]
export const getDevicesAll = async () => {
  const { data } = await axios.get("/devices/all/detailed");
  return data;
};

//--> SettingsTab
//Получаем идентификационный номер устройства
//deviceId - id устройства
export const identifierNumberDevice = async (device_id) => {
  const response = await axios.post(`/devices/identifier`, {
    device_id,
  });
  return response;
};

//--> ProgramsTab
//Получаем отчет о моющих средствах
//deviceId - id устройства
//program_id - id программы
export const fetchDetergentsReport = async (device_id, program_id) => {
  const response = await axios.post(`/devices/program_cleaners`, {
    device_id,
    program_id,
  });
  return response;
};

//--> SettingsTab
//Перемещаем устройство из одной организации в другую
//deviceId - id устройства
//orgId - id Организации
export const movengDevice = async (org_id, device_id) => {
  const response = await axios.put(`/devices/move`, {
    device_id,
    org_id,
  });
  return response;
};

//--> DetergentsTab
// Отчет об уровне моющих средствах
//deviceId - id устройства
export const fetchDetergentsLevel = async (device_id) => {
  const response = await axios.post(`/devices/curr_ms_levels`, {
    device_id,
  });
  return response;
};

//--> EditSchemaTemplate
// Редактирование типа моющих средств
//device_id - id устройства
export const updateCleanerSchema = async (
  device_id,
  register_name,
  type_id,
  description
) => {
  const response = await axios.put(`/devices/update_cleaners_schema`, {
    device_id,
    register_name,
    type_id,
    description,
  });
  return response;
};

//--> DetergentsTab
// Отчет об потреблении МС
//device_id - id устройства
export const fetchCleanersTotal = async (
  by_type,
  device_id,
  dateStart,
  dateEnd
) => {
  const response = await axios.post(`/devices/cleaners_total`, {
    by_type,
    device_id,
    start_dt: dateStart,
    end_dt: dateEnd,
  });
  return response;
};

//-->PlanTechInspectPopup
// Получаем типы ТО (ежемесячный / квартальный / годовой)
export const fetchMaintenanceTypes = async () => {
  const response = await axios.get(`/maintenance/types`);
  return response;
};

//-->PlanTechInspectPopup
// Получаем организацию подрядчика
export const fetchContractors = async () => {
  const response = await axios.get(`/maintenance/contractors`);
  return response;
};

//--> PlanTechInspectPopup
//Создаем новую дату ТО.

export const createNewMaintenance = async (
  device_id,
  type_id,
  planned_date,
  contractor,
  quarterly_value,
  monthly_value,
  yearly_value
) => {
  const response = await axios.put(`/maintenance/new`, {
    device_id,
    type_id,
    planned_date,
    contractor,
    quarterly_value,
    monthly_value,
    yearly_value,
  });
  return response;
};

//--> CalendarApp
//Получаем запланырованные ТО текущей организации
// params:
// org_id
// {
//   "start_dt": "2024-04-11T13:24:42.511Z", - начало месяца
//   "end_dt": "2024-04-30T13:24:42.511Z" - конец месяца
// }
//return [
//   {
//     "id": 2,
//     "device_id": 1,
//     "type_id": 1,
//     "planned_date": "2024-04-29T00:00:00",
//     "actual_date": "2024-04-11T13:24:29.568571",
//     "quarterly_value": 196,
//     "monthly_value": -504,
//     "yearly_value": 1196,
//     "contractor": 1,
//     "conducted": false
//   },
// ]
export const fetchPlannedMaintenance = async (org_id, start_dt, end_dt) => {
  const response = await axios.post(
    `/maintenance/by_organisation/${org_id}/planned`,
    {
      start_dt,
      end_dt,
    }
  );
  return response;
};

//--> CalendarApp
// Получаем запланырованные ТО конкретного устройства.
// export const fetchMaintenancesByDeviceId = async (deviceId) =>{
//   const response = await axios.get(`/maintenance/${deviceId}/get`);
//   return response;
// }

// -- ServicesTab
// Получаем запланированные ТО для конкретного устройства по id.
export const fetchPlannedMaintenanceByeviceId = async (deviceId) => {
  const response = await axios.get(
    `/maintenance/by_device/${deviceId}/planned`
  );
  return response;
};

//--> CalendarApp
// Редактируем ТО.
export const updateTechInspect = async (
  maintenance_id,
  planned_date,
  device_id,
  type_id,
  actual_date,
  quarterly_value,
  monthly_value,
  yearly_value,
  contractor,
  conducted
) => {
  const response = await axios.put(`/maintenance/${maintenance_id}/update`, {
    planned_date,
    device_id,
    type_id,
    actual_date,
    quarterly_value,
    monthly_value,
    yearly_value,
    contractor,
    conducted,
  });
  return response;
};

//--> ProfileComp
//Измсеняем имя устройства
//deviceId - id устройства
//newDeviceName - новое имя устройства
export const updateOrgName = async (org_id, newOrgName, descr) => {
  const response = await axios.put(`/organisations/update`, {
    org_id,
    name: newOrgName,
    description: descr,
  });
  return response;
};

// --> DeleteEmployeeTemplate
// Удаление сотрудника
//params:
//  org_id - id организации
//  user_Id  - id сотрудника, которого хотим удалить
export const removeEmployee = async (org_id, user_Id) => {
  const response = await axios.delete("/organisations/staff/remove_user", {
    data: {
      org_id: org_id,
      user_id: user_Id,
    },
  });
  return response;
};

//--> DeleteOrganizationTemplate
//Удаляем организацию
//orgId - id организации
export const deleteOrganization = async (org_id) => {
  const response = await axios.delete(`/organisations/delete`, {
    data: {
      org_id,
    },
  });
  return response;
};

//--> DetergentsTab
// Отчет об уровне моющих средствах
//deviceId - id устройства
export const moveAllDevices = async (from_org_id, to_org_id) => {
  const response = await axios.put(`/organisations/devices/moveall`, {
    from_org_id,
    to_org_id,
  });
  return response;
};

//--> NewsComponent
// Получаем все непрочитанные ошибки всех устройств пользователя
export const allUnreadErrors = async () => {
  const response = await axios.get(`/devices/errs/unread`);
  return response;
};
