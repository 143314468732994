//модуль для запроса массива приглашений сотрудников в организации

import { getInvitations } from "@/api/api";

export default {
  //action высегда использует context.
  // У context есть метод commit.
  //при помощи деструктуризации из объекта context забираем ТОЛЬКО поле {commit}
  actions: {
    async fetchInvitations({ commit }) {
      try {
        // получаем массив с сервера
        const invitations = await getInvitations();
        //Записываем масив в state через mutations
        //Первым параметром в commit передаем название той мутации, которую хотим вызвать
        // Вторым параметром передаем полученные с сервера данные.
        commit("setInvitations", invitations);
      } catch (error) {
        console.log(error.message);
      }
    },
  },
  mutations: {
    // В мутации первый параметр - это всегда state, а второй параметр - это то,
    // что мы будем в него передавать  (в данном случае)
    // Экшн нужно вызывать в шаблоне, как метод.
    setInvitations(state, invitationsArr) {
      state.invitations = invitationsArr;
    },
  },
  state: {
    invitations: [],
  },
  getters: {
    invitations: (state) => state.invitations,
    invitationsCounter: (state) => state.invitations.length
  },
};
