import store from "@/store/store.js";
import {checkUserMe} from "@/api/api.js"

//Guards for routes
export const authGuard = (to, from, next) => {
  if (!isAuthenticated()) {
    next({ name: "auth" }); // Если требуется аутентификация и пользователь не аутентифицирован, перенаправляем на страницу авторизации
  } else {
    next(); // В остальных случаях продолжаем навигацию
  }

  function isAuthenticated() {
    // Проверкой наличия токена
    return store.getters.getUser !== null;
  }
};


//Guards for resetPasword routes
export const recoveryPasswordGuard = async (to, from, next) => {
  try {
    const user = await checkUserMe();
    if (user.data.username) {
        next({ name: "devices" });
    } 
  } catch (error) {
    next();
  }
}


