//модуль для запроса просмотренных и непросмотренных НОВОСТЕЙ

import { fetchUnreadedNews, setReadNews } from "@/api/api";

export default {
  state: {
    newsList: [],
    unreadNewsList: [],
    allNewsList: [], // Это может быть список всех новостей
    newsInterval: null, // Для хранения идентификатора интервала
  },
  //action высегда использует context.
  // У context есть метод commit.
  //при помощи деструктуризации из объекта context забираем ТОЛЬКО поле {commit}
  // Получает список новостей
  actions: {
    async fetchNews({ commit }) {
      // Получаем список непрочитанных новостей
      try {
        const response = await fetchUnreadedNews();
        if (response.status == 200 && response?.data) {
          // Помещаем полученный массив непрочитанных новостей в state
          commit("setNewsNotifications", response.data);
        } else {
          console.error("Не удалось получить список новостей.");
        }
      } catch (error) {
        console.error("Ошибка при получении непрочитанных новостей:", error.message || error);
      }
    },
    async setNewsIsView({ dispatch }, news_id) {
      // Делаем новость прочитанной
      try {
        const response = await setReadNews(news_id);
        // Проверяем успешность запроса, например, по статусу или данным ответа
        if (response && response.status === 200) {
          // Перезапрашиваем список ошибок, чтобы обновить стейт
          await dispatch("fetchNews");
        } else {
          console.error("Не удалось установить ошибку как прочитанную. Проверьте ответ сервера:", response);
        }
      } catch (error) {
        console.error("Ошибка при установке ошибки как прочитанной:", error.message);
      }
    },
    // Запускаем запрос новостей каждую минуту
    startNewsFetching({ dispatch, commit }) {
      dispatch("fetchNews"); // Сразу запрашиваем новости

      const interval = setInterval(() => {
        dispatch("fetchNews");
      }, 60000); // Запрос новостей один раз в минуту (60000 миллисекунд)

      commit("setNewsInterval", interval); // Сохраняем интервал в state
    },
    // Останавливаем периодический запрос новостей
    stopNewsFetching({ commit, state }) {
      if (state.newsInterval) {
        clearInterval(state.newsInterval); // Останавливаем интервал
        commit("clearNewsInterval"); // Очищаем значение интервала в state
      }
    },

  },
  mutations: {
    // В мутации первый параметр - это всегда state, а второй параметр - это то,
    // что мы будем в него передавать  (в данном случае)
    // Экшн нужно вызывать в шаблоне, как метод.
    // Записывает полученные непрочитанные новости в state
    setNewsNotifications(state, unreadNews) {
      state.unreadNewsList = unreadNews; //Записываем массив новостей
    },
    setNewsInterval(state, interval) {
      state.newsInterval = interval; // Сохраняем идентификатор интервала
    },
    clearNewsInterval(state) {
      state.newsInterval = null; // Очищаем интервал
    },
  },

  getters: {
    // Возвращает список НЕПРОЧИТАННЫХ новостей
    getUnreadNewsList: (state) => state.unreadNewsList,
    // Возвращает количество (счетчик) НЕПРОЧИТАННЫХ новостей
    unreadNewsListCount: (state) => state.unreadNewsList.length,
    // Возвращает список ВСЕХ новостей
    getNewsList: (state) => state.allNewsList,
    
  },
};
