<template>
  <transition name="slide-fade">
    <div class="section">
      <div v-if="content.length != 0" class="news_box">
        <div v-for="news in content" :key="news.id" class="box_wrapper">
          <div class="date_news">{{ dateStringFormat(news.added_at) }}</div>
          <!-- MarkDown -->
          <div v-html="renderMarkdown(news.content)"></div>
          <div class="readed_btn">
            <button
              class="btn"
              @click="handleClick(news.id)"
              v-if="!isRead(news.id)"
            >
              Прочитать
            </button>
          </div>
          <div class="errMessage">
            {{ errReadNews }}
          </div>
        </div>
      </div>
      <div class="no_data_block" v-else>
        <span>Новых новостей нет</span>
        <span class="link">Перейти в архив</span>
      </div>
    </div>
  </transition>
</template>

<script>
import { marked } from "marked";
import DOMPurify from "dompurify";
import { dateStringFormat } from "@/utils";
import { mapActions } from "vuex";

export default {
  props: {
    content: {
      type: Array,
    },
  },
  data() {
    return {
      readNewsIds: [],
      errMessage: "",
      errReadNews: "",
    };
  },
  methods: {
    ...mapActions(["setNewsIsView"]),
    dateStringFormat,
    renderMarkdown(markdownText) {
      let dirty = marked(markdownText);
      dirty = dirty.replace(/<li>/g, '<li><span class="bullet">•</span> ');
      return DOMPurify.sanitize(dirty);
    },
    async handleClick(newsId) {
      // Делаем новость прочитанной (кнопка "Прочитать")
      this.setNewsIsView(newsId);
    },
    isRead(newsId) {
      return this.readNewsIds.includes(newsId); // Проверяем, есть ли id новости в массиве прочитанных
    },

    modalClose() {
      this.isModal = !this.isModal;
    },
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.5); /* Затемнение фона */
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Выравнивание элементов по началу страницы */
  padding-top: 100px; /* Расстояние сверху */
  z-index: 998; /* Положение поверх всего остального */
}
.no_data_block{
  margin-top: 25px;
}
.link {
  margin-left: 15px;
  color: #646464;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}
.box_wrapper{
  margin: 3px 3px 15px 3px;
}
.update_message {
  position: relative;
  width: 80%;
  background-color: var(--nav-bg-color);
  padding: 20px;
  padding-top: 0;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
  overflow: hidden; /* Добавляем это, чтобы контент не выходил за пределы блока */
}

.news_box {
  max-height: calc(60vh - 50px); /* Учтем padding и заголовок */
  overflow-y: auto; /* Включение вертикальной прокрутки */
}

.news_box::-webkit-scrollbar {
  width: 10px;
  background-color: none;
}

.news_box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--active-text-color);
}

.news_box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: none;
  margin-top: 15px;
  margin-bottom: 15px;
}

/* .line {
  border-top: 1px solid rgb(197, 196, 196);
  margin-bottom: 15px;
} */

.header_news {
  display: flex;
  justify-content: space-between;
}

.readed_btn {
  display: flex;
  justify-content: end;
  margin-top: 15px;
}

.date_news {
  color: #646464;
}

.button-close {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 24px;
  z-index: 998;
}
.display_none {
  display: none;
}
</style>
