<template>
  <div class="tab_nav">
    <div
      @click="clickOnTab(tab.name)"
      v-for="tab in names"
      :key="tab.name"
      :class="[
        'tab_item invitation_wrapper',
        { active_tab: tab.name === selectedTab },
      ]"
    >
      {{ tab.name }}

      <!-- Индикатор ТО "!" (отображается, если есть непросмотренные ошибки) -->
      <div
        class="maintenance_indicator"
        v-if="tab.name === 'Ошибки' && errorsIndicate"
      >
        <!-- СЧЕТЧИК НЕПРОЧИТАННЫХ ОШИБОК-->
        <span
          :class="[
            'indicate_counter',
            { active_tab: tab.name === selectedTab },
          ]"
          >{{ errorsUnreadCounter }}</span
        >
      </div>
      <!-- СЧЕТЧИК НЕПРОЧИТАННЫХ ОШИБОК (отображается, если есть непросмотренные ошибки) -->
      <div
        class="maintenance_indicator"
        v-if="tab.name === 'Все ошибки' && allUnreadErrorsCount > 0"
      >
        <span
          :class="[
            'indicate_counter',
            { active_tab: tab.name === selectedTab },
          ]"
          >{{ allUnreadErrorsCount }}</span
        >
      </div>

      <!-- СЧЕТЧИК НЕПРОЧИТАННЫХ НОВОСТЕЙ  (отображается, если есть непросмотренные ошибки) -->
      <div
        class="maintenance_indicator"
        v-if="tab.name === 'Новости' && unreadNewsListCount > 0"
      >
        <span
          :class="[
            'indicate_counter',
            { active_tab: tab.name === selectedTab },
          ]"
          >{{ unreadNewsListCount }}</span
        >
      </div>

      <!-- Индикатор ТО "!" (отображается, если приблтжается плановое ТО) -->
      <div
        class="maintenance_indicator"
        v-if="tab.name === 'Сервис' && maintenanceIndicate"
      >
        <!-- ВОСКЛИЦАТЕЛЬНЫЙ ЗНАК  УВЕДОМЛЕНИЯ-->
        <svg
          width="13px"
          height="13px"
          viewBox="0 0 64 64"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          aria-hidden="true"
          role="img"
          class="iconify iconify--emojione"
          preserveAspectRatio="xMidYMid meet"
        >
          <!-- Define the filter -->
          <defs>
            <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
              <feDropShadow
                dx="3"
                dy="3"
                stdDeviation="7"
                flood-color="#000000"
                flood-opacity="0.4"
              />
            </filter>
          </defs>

          <!-- Apply the filter to the group -->
          <g>
            <path d="M37 42.4H27L23 2h18z"></path>
            <ellipse cx="32" cy="54.4" rx="7.7" ry="7.6"></ellipse>
          </g>
        </svg>
      </div>
      <div
        class="maintenance_indicator"
        v-if="tab.name === 'Приглашения' && invitations.length"
      >
        <span
          :class="[
            'indicate_counter',
            { active_tab: tab.name === selectedTab },
          ]"
          >{{ invitations.length }}</span
        >
      </div>
    </div>
  </div>
  <div class="tab_content">
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "TabsApp",
  emits: ["changeTab"],
  props: {
    names: {
      type: Array,
      required: true,
    },
    selectedTab: {
      type: String,
      required: true,
    },
    deviceId: {
      type: Number,
    },
  },
  methods: {
    ...mapActions(["fetchMetrics"]),
    clickOnTab(tabName) {
      this.$emit("changeTab", tabName);
    },
  },
  computed: {
    // "invitations" - Получаем приглашения из хранилища, проверяем, есть ли приглашения в массиве "invitations"
    // Если есть, то отображаем индикатор в шаблоне (индикатор в кнопке "Уведомления")
    // counterMonthly/counterQuarterly/counterAnnual (maintenance vuex) - Итоговые показатели ТО.
    // Если эти показатели иеньше 50 часов (Ежемесячное) и меньше 100 (квартальное и годовое),
    // то выводим красный индикатор в кнопке (Tab-name) Сервис
    ...mapGetters([
      "invitations",
      "counterMonthly",
      "counterQuarterly",
      "counterAnnual",
      "errorsListState", // список прочитанных и непрочитанных ошибок
      "errorsCountState", // количество непрочитанных ошибок
      "unreadNewsListCount", // Количество непрочитанных новостей
      "allUnreadErrorsCount",
    ]),
    maintenanceIndicate() {
      const indicate =
        this.counterMonthly < 50 ||
        this.counterQuarterly < 100 ||
        this.counterAnnual < 100;
      return indicate;
    },
    // Если в массиве есть непрочитанные сообщения, то отрисовываем восклизательный знак
    errorsIndicate() {
      return this.errorsListState.some((err) => err.isViewed === false);
    },

    // Возвращает кол-во непрочитанных ошибок (счетчик в кнопке таба)
    errorsUnreadCounter() {
      const errorsCount = this.errorsListState.filter(
        (err) => err.isViewed === false
      );
      return errorsCount?.length;
    },
  },
  watch: {
    //Когда id устройства меняется, из хранилища делается запрос на сервер
    // для получения итоговых показателей ТО, что бы отображать индикатор, если...
    // this.counterMonthly < 50 || this.counterQuarterly < 100 || this.counterAnnual < 100
    deviceId() {
      this.fetchMetrics(this.deviceId);
    },
  },
};
</script>

<style scoped>
.invitation_wrapper {
  position: relative;
}

.maintenance_indicator {
  fill: red;
}

.tab_nav {
  display: flex;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.indicate_counter {
  font-size: 12px;
  position: absolute;
  top: 0;
  right: 0;
}

.tab_content {
  margin-bottom: 105px;
}

.tab_item {
  border-radius: var(--border-radius);
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  cursor: pointer;
  font-size: var(--min-size);
  background-color: var(--nav-bg-color);
  box-shadow: var(--box-shadow);
  transition: 0.5s;
}
.tab_item:hover {
  background-color: var(--hover-btn);
}
.active_tab,
.active_tab * {
  background-color: var(--active-text-color);
  color: #ffffff;
  transition: 0.5s;
}

.active_tab:hover,
.active_tab:hover * {
  background-color: var(--hover-active-btn);
}

@media (max-width: 1092px) {
  .tab_item {
    font-size: var(--min-size);
  }
}
</style>
