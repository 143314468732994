// store/modules/devices.js

import { getDevicesAll } from "@/api/api";

export default {
 
  state: {
    devicesAllList: [],
    interval: null,
  },
  mutations: {
    setDevicesList(state, devices) {
      state.devicesAllList = devices;
    },
    setInterval(state, interval) {
      state.interval = interval;
    },
    clearInterval(state) {
      if (state.interval) {
        clearInterval(state.interval);
        state.interval = null;
      }
    },
  },
  actions: {
    async fetchDevices({ commit }) {
      try {
        const res = await getDevicesAll();
        const sortedDevices = res.sort((a, b) => a.id - b.id);
        
        commit("setDevicesList", sortedDevices);
      } catch (err) {
        console.error("Error fetching devices:", err);
      }
    },
    async startFetchingDevices({ commit, dispatch }) {
      // Fetch devices initially
      await dispatch("fetchDevices");

      // Start interval to fetch devices every 20 seconds
      const interval = setInterval(async () => {
        await dispatch("fetchDevices");
      }, 20000);
      commit("setInterval", interval);
    },
    stopFetchingDevices({ commit }) {
      commit("clearInterval");
    },
  },
  getters: {
    devicesAllList: (state) => state.devicesAllList,
  },
};
