//модуль для запроса просмотренных и непросмотренных ошибок в Устройствах вкладка ошибки

import { allUnreadErrors, getErrorList, setErrorIsView } from "@/api/api";

export default {
  state: {
    errorsList: [],
    allUnreadErrors: [],
    errorFetchInterval: null,
  },
  //action высегда использует context.
  // У context есть метод commit.
  //при помощи деструктуризации из объекта context забираем ТОЛЬКО поле {commit}
  // Получает список ошибок для конкретного устройства
  actions: {
    async fetchAllUnreadErrors({ commit }) {
      // Получаем все непрочитанные/непросмотренные ошибки.
      try {
        const response = await allUnreadErrors();
        if (response.status == 200 && response?.data) {
          commit("setAllUnreadErrorsList", response.data);
        } else {
          console.error("Не удалось получить список ошибок.");
        }
      } catch (error) {
        console.error("Ошибка получения списка ошибок.");
      }
    },
    async fetchDeviceErrors({ commit }, device_id) {
      try {
        // получаем массив со всеми ошибками с сервера
        const errorsList = await getErrorList(device_id);
        // Записываем масив в state через mutations
        // Первым параметром в commit передаем название той мутации, которую хотим вызвать
        // Вторым параметром передаем полученные с сервера данные.
        commit("setErrorsNotifications", errorsList.data);
      } catch (error) {
        console.error("Ошибка при запросе списка ошибок:", error.message);
      }
    },

    async errorIsView({ dispatch }, { error_id, device_id }) {
      // Делаем ошибку прочитанной (Для конкретного устройства в блоке Устройства => Ошибки)
      // Проверка на существование error_id, device_id
      if (!error_id || !device_id) {
        console.error(
          "Недостаточно данных: отсутствуют error_id или device_id."
        );
        return;
      }

      // Делаем ошибку прочитанной
      try {
        const response = await setErrorIsView(error_id, device_id);

        // Проверяем успешность запроса, например, по статусу или данным ответа
        if (response && response.status === 200) {
          // Перезапрашиваем список ошибок, чтобы обновить стейт
          await dispatch("fetchDeviceErrors", device_id);
          await dispatch("fetchAllUnreadErrors");
        } else {
          console.error(
            "Не удалось установить ошибку как прочитанную. Проверьте ответ сервера:",
            response
          );
        }
      } catch (error) {
        console.error(
          "Ошибка при установке ошибки как прочитанной:",
          error.message
        );
      }
    },
    async newErrorIsView({ dispatch }, err_id) {
      // Делаем ошибку прочитанной (Для общего компонента в Уведомлениях )
      if (!err_id) {
        console.error("Недостаточно данных: отсутствуют error_id");
        return;
      }
      // Делаем ошибку прочитанной
      try {
        const response = await setErrorIsView(err_id);
        // Проверяем успешность запроса, например, по статусу или данным ответа
        if (response && response.status === 200) {
          // Перезапрашиваем список ошибок, чтобы обновить стейт
          await dispatch("fetchAllUnreadErrors");
        } else {
          console.error(
            "Не удалось установить ошибку как прочитанную. Проверьте ответ сервера:",
            response
          );
        }
      } catch (error) {
        console.error(
          "Ошибка при установке ошибки как прочитанной:",
          error.message
        );
      }
    },
    // setInterval
    startErrorFetchInterval({ dispatch }) {
      // Запускаем интервал для запроса ошибок каждые 60 секунд
      dispatch('fetchAllUnreadErrors'); // Запрашиваем сразу при старте

      const intervalId = setInterval(() => {
        dispatch('fetchAllUnreadErrors');
      }, 60000); // 60 секунд

      // Сохраняем идентификатор интервала в состоянии
      return intervalId;
    },
    stopErrorFetchInterval({ state }) {
      if (state.errorFetchInterval) {
        clearInterval(state.errorFetchInterval);
      }
    }
  },
  mutations: {
    // В мутации первый параметр - это всегда state, а второй параметр - это то,
    // что мы будем в него передавать  (в данном случае)
    // Экшн нужно вызывать в шаблоне, как метод.
    // Записывает полученные ошибки в state
    setErrorsNotifications(state, errorNotification) {
      state.errorsList = errorNotification;
    },
    setAllUnreadErrorsList(state, news) {
      state.allUnreadErrors = news;
    },
    setErrorFetchInterval(state, intervalId) {
      state.errorFetchInterval = intervalId;
    }
  },

  getters: {
    //Возвращает ВСЕ непрочитанные ошибки
    allUnreadErrorsList: (state) => state.allUnreadErrors,
    //Возвращает КОЛИЧЕСТВО ВСЕХ непрочитанных ошибок
    allUnreadErrorsListCounter: (state) => state.allUnreadErrors.length,
    //Возвращает количество непрочитанных ошибок для конкретного устройства
    allUnreadErrorsCount: (state) => state.allUnreadErrors.length,
    // Возвращает список ошибок
    errorsListState: (state) => state.errorsList,
    // Возвращает количество ошибок
    errorsCountState: (state) => state.errorsList.length,
    // //Возвращает количество ВСЕХ уведомлений (непрочитанные ошибки + непрочитанные новости)
    // allNotificationsCounter: (state) =>
  },
};
