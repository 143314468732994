import { createStore } from "vuex";
import user from "./modules/user"
import devices from "./modules/devices"
import resetPassword from "./modules/resetPassword"
import invitations from "./modules/invitations";
import notification from "./modules/notification";
import activeDevice from "./modules/activeDevice";
import maintenance from "./modules/maintenance";
import planningMaintenance from "./modules/planningMaintenance";
import errorsNotifications from "./modules/errorsNotifications"
import currentSeriesDevice from "./modules/currentSeriesDevice";
import newsNotifications from "./modules/newsNotifications";

const state = {};

const store = createStore({
  state,
  actions: {},
  mutations: {},
  getters: {},

  //Импорт отдельных модулей хранилища.
  modules: {
    user, // Текущий пользователь
    devices, // Список всех устройств -> DevicesComponent
    invitations, // Уведомления
    notification, // Всплывающие подсказки
    resetPassword, // Восстановление пароля
    activeDevice, // Активное устройство
    maintenance, // Техническое обслуживание устройства (Ежемесячное, Квартальное, Годовое)
    planningMaintenance, //Отображение и планирование ТО
    newsNotifications, // Уведомления о новостях, список новостей, количество непрочитанных новостей.
    errorsNotifications, // Уведомления (счетчик непрочитанных ошибок) о просмотренных и непросмотренных ошибках в Устройствах вкладка "Ошибки" и в Главных уведомлениях в хэдэре.
    currentSeriesDevice // Устанавливает и возвращает серию(вид) устройства (DeviceComponent). Вкладка "Моющие средства" не отображаются, если устройство является сушильныи, то есть не потребляет моющие средства
  },
});

export default store;
