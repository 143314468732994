//модуль для запроса массива приглашений сотрудников в организации

export default {
  //action высегда использует context.
  // У context есть метод commit.
  //при помощи деструктуризации из объекта context забираем ТОЛЬКО поле {commit}
  actions: {
    updateMessage({ commit }, {text, access}) {
      const message = {text, access}
      //Записываем сообщение в state через mutations
      //Первым параметром в commit передаем название той мутации, которую хотим вызвать
      // Вторым параметром текст сообщения.
      commit("setNotifMessage", message);
      //удаляем текст оповещения из хранилища через 3 секунды
      setTimeout(()=>{
        commit("clearNotifMessage")
      }, 3000)
    },
  },
  mutations: {
    // В мутации первый параметр - это всегда state, а второй параметр - это то,
    // что мы будем в него передавать  (в данном случае)
    // Экшн нужно вызывать в шаблоне, как метод.
    setNotifMessage(state, message) {
      state.notifMessage = message;
    },

    clearNotifMessage(state) {
      state.notifMessage = {};
    },
  },
  state: {
    notifMessage: {},
  },
  getters: {
    notifMessage: (state) => state.notifMessage,
  },
};
