//модуль для запроса массива приглашений сотрудников в организации

export default {
 
  actions: {
    //Записываем пользователя в хранилище
    setUser({commit}, user){
      commit("setUser", user)
    },
    // добавляем Id текущего пользователя в хранилище
    setUserId({commit}, userId){
      commit("setUserId", userId)
    },
    // удаление пользователя из хранилища посли ралогинивания
    // Этот action вызывает мутацию removeUser, которая удаляет пользователя из состояния.
    removeUser({ commit }) {
      commit("removeUser");
    },
    // Удаление id пользователя из хранилища
    removeUserId({commit}){
      commit("removeUserId")
    }
  },
  mutations: {
    setUser(state, user){
      state.user = user
    },
    setUserId(state, userId){
      state.userId = userId;
    },
    // Эта мутация сбрасывает пользователя в null после выхода из системы.
    removeUser(state) {
      state.user = null;
    },
    // Эта мутация сбрасывает id пользователя в null после выхода из системы.
    removeUserId(state){
      state.userId = null;
    }
  },
  state: {
    user: null,
    userId: null
  },
  getters: {
   getUser: (state)=> state.user,
   getUserId: (state) => state.userId,
  },
};
