// модуль для контроля активного устройства

export default {
  actions: {
    setActiveDevice(context, activeDevice) {
      context.commit("setActiveDevice", activeDevice);
    },
  },
  mutations: {
    setActiveDevice(state, activeDevice) {
      state.activeDevice = activeDevice;
    },
  },
  state:{
    activeDevice: null,
  },
  getters: {
    // текущее активное устройство (активная вкладка при переходе из "Предстоящих событий")
    getActiveDevice: (state) => state.activeDevice, // активное устройство
  },
};

