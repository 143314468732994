// модуль для восстановления пароля

export default {
  actions: {
    resetPasswordToken({ commit }, resetPasswordToken) {
      commit("resetPasswordToken", resetPasswordToken);
    },
  },
  mutations: {
    resetPasswordToken(state, resetPasswordToken) {
      state.resetPasswordToken = resetPasswordToken;
    },
  },
  state: {
    resetPasswordToken: null,
  },
  getters: {
    // Получаем уведомления (приглашени сотрудника в организацию)
    getResetPasswordToken: (state) => state.resetPasswordToken,
  },
};
