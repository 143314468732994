// Импорты CSS
import "../src/assets/css/reset.css"; //обнуляющие стили
import "../src/assets/css/title_section.css" //стили заголовка секции
import "../src/assets/css/select.css"; //стили для компонентов select
import "../src/assets/css/input.css"; //стили для компонентов input
import "../src/assets/css/btn.css"; //стили для компонентов кнопок
import "../src/assets/css/checkbox.css"; //стили для checkbox
import "../src/assets/css/btn_disabled.css"; //стили для неактивной кнопки (disabled=true)
import "../src/assets/css/animation_popup.css"; //стили для анимации модальных окон.
import "../src/assets/css/table.css"; // стили для таблиц
import "../src/assets/css/box.css"; //стили для контейнера-обертки

// Импорты Vue.js и других зависимостей
import { createApp } from "vue";
import App from "./App.vue";
import "./api/axios";
import VueApexCharts from "vue3-apexcharts";
import cookies from "vue-cookies";
import router from "./router/routes";
import store from "./store/store";
import cors from "cors";
import "./registerServiceWorker";
import YmapPlugin from 'vue-yandex-maps';

// Настройка плагинов
const settings = {
  apiKey: process.env.VUE_APP_API_KEY_YANDEX, //ключ для карты Yandex, (хранится в .env)
  lang: 'ru_RU',
  enterprise: false,
  version: '2.1',
};
// Создание экземпляра приложения и его настройка
const app = createApp(App);
app.use(VueApexCharts);
app.use(cookies);
app.use(cors);
app.use(router);
app.use(store);
app.use(YmapPlugin, settings);

// Монтирование приложения
app.mount("#app");
