<template>
  <div v-if="getUser" class="menu" id="container">
    <div class="navbar">
      <ul>
        <li>
          <router-link to="/devices">
            <strong class="icon_svg">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="45"
                height="45"
                viewBox="0 0 900 900"
                preserveAspectRatio="xMidYMid meet"
              >
                <g
                  transform="translate(0.000000,900.000000) scale(0.100000,-0.100000)"
                  fill="#696969"
                  stroke="none"
                >
                  <path
                    d="M1618 8830 c-171 -29 -353 -136 -465 -272 -52 -64 -113 -178 -144
-273 -24 -70 -24 -70 -27 -717 l-3 -648 3521 0 3521 0 -3 648 -3 647 -24 70
c-90 269 -256 436 -526 526 l-70 24 -2865 1 c-1576 1 -2886 -2 -2912 -6z
m3984 -628 c66 -29 143 -104 175 -171 25 -49 28 -67 28 -151 -1 -76 -5 -105
-23 -143 -30 -66 -101 -138 -169 -173 -52 -26 -68 -29 -153 -29 -83 0 -102 3
-151 28 -67 32 -141 108 -171 174 -32 69 -32 218 -1 285 40 87 127 165 216
194 61 20 190 13 249 -14z m1290 -5 c66 -32 141 -109 171 -175 31 -67 31 -216
-1 -285 -30 -66 -101 -138 -169 -173 -52 -26 -68 -29 -153 -29 -85 0 -101 3
-153 29 -68 35 -140 108 -170 174 -31 67 -31 217 0 284 25 54 90 128 140 159
92 57 237 64 335 16z m-3426 -23 c68 -51 69 -53 69 -294 0 -241 -1 -243 -69
-294 -27 -21 -36 -21 -726 -21 -690 0 -699 0 -726 21 -67 49 -69 57 -72 267
-4 221 -1 245 35 288 51 61 25 59 775 56 676 -2 687 -2 714 -23z"
                  />
                  <path
                    d="M982 3713 l3 -2888 23 -70 c75 -221 206 -376 397 -471 178 -87 -145
-79 3095 -79 3240 0 2917 -8 3095 80 156 77 264 185 341 340 88 179 79 -154
82 3093 l2 2882 -3520 0 -3520 0 2 -2887z m3728 1916 c533 -49 1025 -287 1400
-675 324 -336 521 -730 602 -1204 29 -168 31 -499 5 -670 -60 -386 -197 -715
-431 -1030 -90 -121 -313 -345 -436 -437 -309 -230 -645 -371 -1030 -430 -160
-24 -493 -24 -645 1 -485 79 -883 275 -1225 603 -919 880 -919 2345 -1 3225
476 456 1108 678 1761 617z"
                  />
                  <path
                    d="M4345 4994 c-709 -76 -1277 -596 -1417 -1297 -32 -161 -32 -433 0
-594 64 -320 212 -600 437 -827 231 -232 515 -384 838 -448 152 -30 424 -32
572 -4 437 82 798 316 1044 676 63 91 153 259 145 271 -2 4 -19 10 -38 13 -77
15 -195 86 -275 165 -254 252 -254 650 0 902 80 79 198 150 275 165 19 3 36
10 39 14 7 12 -78 172 -143 267 -243 354 -597 589 -1017 673 -98 20 -366 34
-460 24z m-191 -576 c9 -12 16 -29 16 -38 0 -8 -26 -52 -59 -96 -336 -470
-334 -1315 4 -1773 59 -79 64 -94 39 -129 -23 -33 -63 -28 -162 22 -303 153
-521 416 -609 733 -25 88 -28 114 -28 263 0 149 3 175 28 263 87 315 298 571
602 729 102 53 145 60 169 26z"
                  />
                </g>
              </svg>
            </strong>
            <span class="menu_title">Устройства</span>
          </router-link>
        </li>
        <!-- <li>
          <router-link to="/dashboard">
            <strong class="icon_svg">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="45"
                height="45"
                viewBox="0 0 893 893"
                preserveAspectRatio="xMidYMid meet"
              >
                <g
                  transform="translate(0,893.000000) scale(0.100000,-0.100000)"
                  fill="#696969"
                  stroke="none"
                >
                  <path
                    d="M4380 8734 c-94 -18 -179 -59 -265 -126 -77 -60 -3710 -3693 -3776
-3776 -114 -142 -155 -281 -131 -443 29 -199 152 -347 356 -427 34 -13 101
-17 371 -22 l330 -5 5 -1660 c5 -1585 6 -1662 24 -1710 46 -127 151 -247 264
-304 122 -62 106 -61 1015 -61 l827 0 0 1347 c0 935 3 1366 11 1412 34 200
195 375 394 426 50 13 154 15 665 15 660 0 664 0 780 -58 78 -39 189 -151 228
-232 64 -130 62 -70 62 -1557 l0 -1353 828 0 c694 0 837 3 890 15 172 41 313
166 383 340 l24 60 5 1660 5 1660 330 5 c360 6 352 5 476 71 224 120 323 445
209 684 -51 107 -132 197 -585 649 l-435 433 0 985 c0 1083 2 1039 -62 1161
-65 122 -181 219 -312 260 -68 21 -86 22 -421 22 -379 0 -402 -3 -515 -60
-116 -59 -215 -174 -261 -305 -20 -57 -24 -88 -27 -264 l-4 -199 -616 615
c-397 395 -638 628 -677 653 -91 59 -174 86 -280 90 -49 2 -101 1 -115 -1z"
                  />
                </g>
              </svg>
            </strong>

            <span class="menu_title">Главная</span>
          </router-link>
        </li> -->
        <div class="line"></div>
        <li>
          <router-link to="/analytics">
            <strong class="icon_svg">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="45"
                height="45"
                viewBox="0 0 800.000000 800.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <g
                  transform="translate(0.000000,800.000000) scale(0.100000,-0.100000)"
                  fill="#696969"
                  stroke="none"
                >
                  <path
                    d="M5145 7984 c-16 -2 -80 -11 -142 -20 -62 -8 -165 -28 -230 -44 -1001
-244 -1760 -1048 -1947 -2063 -125 -675 28 -1386 421 -1949 29 -42 53 -80 53
-85 0 -4 -141 -149 -313 -321 l-312 -312 -105 105 c-58 58 -108 104 -112 103
-4 -2 -559 -554 -1233 -1227 l-1227 -1224 474 -474 473 -473 1230 1230 1230
1230 -107 107 -108 108 317 318 316 317 33 -25 c237 -181 575 -339 910 -424
210 -54 322 -65 624 -66 313 0 406 11 650 74 535 138 1025 454 1369 881 302
375 481 786 558 1280 27 175 24 568 -5 749 -96 586 -350 1076 -768 1481 -397
384 -860 614 -1424 706 -91 15 -551 28 -625 18z m627 -657 c335 -71 657 -228
892 -432 222 -195 376 -393 497 -640 266 -547 266 -1180 -1 -1730 -60 -124
-171 -300 -234 -370 l-35 -40 -1 647 0 647 -27 -8 c-93 -27 -185 -90 -428
-295 -133 -112 -323 -248 -372 -267 l-23 -9 0 -654 0 -654 -22 -5 c-13 -3 -61
-16 -108 -30 -47 -13 -102 -27 -122 -31 l-38 -7 -2 662 -3 661 -47 18 c-68 26
-155 91 -298 225 -189 176 -307 268 -442 345 l-58 32 0 -956 c0 -791 -2 -956
-13 -956 -17 0 -118 32 -209 66 l-68 26 0 935 0 936 -73 -6 c-191 -16 -440
-106 -687 -247 -48 -28 -91 -57 -94 -66 -3 -9 -6 -201 -6 -427 l-1 -412 -44
70 c-198 309 -309 737 -291 1121 44 952 742 1725 1689 1869 65 10 159 13 322
10 198 -3 246 -7 347 -28z"
                  />
                  <path
                    d="M4655 5943 c-51 -13 -118 -78 -130 -126 -4 -14 -12 -30 -18 -35 -12
-9 -537 -192 -550 -192 -5 0 -18 9 -30 21 -57 54 -158 70 -222 36 -66 -34
-115 -112 -115 -182 0 -68 49 -148 110 -180 127 -65 290 32 290 172 l0 40 248
86 c136 47 254 88 262 92 11 4 26 -9 47 -39 76 -107 227 -117 312 -21 16 18
31 39 33 46 3 8 134 -64 369 -201 l365 -214 -4 -56 c-5 -70 26 -132 87 -175
37 -26 51 -30 109 -30 59 0 72 4 114 32 40 29 87 96 88 129 1 5 78 54 173 108
95 55 262 152 372 216 285 167 262 157 294 126 35 -33 72 -46 134 -46 100 0
181 75 194 177 12 107 -70 208 -181 220 -113 13 -216 -78 -216 -191 0 -41 -3
-46 -42 -69 -24 -13 -200 -116 -392 -227 -213 -124 -351 -199 -354 -191 -2 6
-15 25 -29 42 -60 71 -170 90 -252 44 l-44 -26 -92 53 c-50 29 -222 130 -382
223 -279 163 -291 172 -303 208 -15 50 -78 113 -127 126 -39 11 -83 12 -118 4z
m114 -109 c65 -46 57 -145 -16 -179 -102 -49 -194 86 -114 166 34 34 92 40
130 13z m2286 -7 c20 -16 28 -35 32 -69 5 -42 2 -50 -24 -77 -39 -39 -71 -46
-117 -27 -43 18 -71 71 -61 117 16 71 115 104 170 56z m-3209 -276 c44 -27 60
-77 40 -124 -44 -106 -196 -76 -196 38 0 85 83 131 156 86z m2047 -302 c61
-66 17 -169 -73 -169 -60 0 -100 41 -100 103 0 97 105 137 173 66z"
                  />
                </g>
              </svg>
            </strong>
            <span class="menu_title">Аналитика</span>
          </router-link>
        </li>
        <div class="line"></div>
        <li>
          <router-link to="/monitoring">
            <strong class="icon_svg">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="45"
                height="45"
                viewBox="0 0 900.000000 900.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <g
                  transform="translate(0.000000,900.000000) scale(0.100000,-0.100000)"
                  fill="#696969"
                  stroke="none"
                >
                  <path
                    d="M7850 8601 c-8 -5 -36 -18 -63 -30 -66 -29 -128 -90 -163 -158 -26
-52 -29 -67 -28 -148 1 -49 4 -104 9 -122 7 -30 -6 -51 -232 -365 -131 -183
-287 -400 -345 -483 -59 -82 -123 -172 -143 -200 -20 -27 -130 -180 -244 -340
-114 -159 -248 -346 -297 -415 l-90 -125 -62 -8 c-34 -4 -86 -17 -114 -28
l-52 -20 -582 290 -583 290 -11 43 c-41 169 -175 278 -340 278 -95 0 -170 -29
-237 -93 -72 -68 -105 -139 -106 -230 l-1 -70 -321 -256 c-176 -140 -439 -350
-585 -466 -377 -300 -329 -269 -400 -257 -114 21 -227 -16 -309 -99 -44 -45
-59 -71 -91 -156 -10 -26 -23 -36 -73 -56 -84 -33 -667 -268 -797 -321 -58
-24 -146 -60 -197 -81 l-91 -37 -67 33 c-176 86 -376 17 -462 -160 -24 -49
-27 -68 -28 -151 0 -86 3 -100 29 -148 91 -164 265 -233 430 -171 97 36 195
146 215 242 7 32 20 38 411 192 107 42 204 80 215 85 11 5 54 22 95 39 191 75
287 113 315 125 17 8 52 22 79 32 48 17 49 17 90 -3 77 -39 111 -47 181 -47
186 3 335 154 335 339 0 57 2 63 33 85 35 26 366 289 516 409 51 42 116 94
145 116 28 21 163 129 300 238 214 170 253 198 275 193 97 -22 194 -14 248 20
12 8 29 14 36 14 8 0 276 -130 595 -289 l581 -290 11 -43 c27 -111 92 -196
187 -244 48 -24 73 -30 144 -32 77 -4 92 -1 144 24 66 31 128 83 159 134 50
81 65 193 37 278 -10 29 -11 49 -5 58 5 7 171 240 369 516 198 276 367 512
375 524 8 12 27 39 42 60 14 22 147 207 295 413 l268 375 72 12 c165 28 267
120 303 273 35 148 -45 310 -187 380 -26 12 -56 27 -65 32 -22 11 -147 11
-168 0z"
                  />
                  <path
                    d="M7252 6368 c-9 -9 -12 -686 -12 -2984 0 -1635 3 -2979 6 -2988 6 -14
76 -16 690 -16 l684 0 0 3000 0 3000 -678 0 c-514 0 -681 -3 -690 -12z"
                  />
                  <path
                    d="M3835 4821 c-6 -6 -9 -825 -7 -2225 l2 -2216 680 0 680 0 5 23 c3 12
4 1012 3 2222 l-3 2200 -675 3 c-421 1 -679 -1 -685 -7z"
                  />
                  <path
                    d="M5537 3973 c-4 -3 -7 -813 -7 -1800 l0 -1793 688 2 687 3 0 1795 0
1795 -681 3 c-374 1 -684 -1 -687 -5z"
                  />
                  <path
                    d="M2117 3463 c-4 -3 -7 -699 -7 -1545 l0 -1538 685 0 685 0 -2 1542 -3
1543 -676 3 c-371 1 -679 -1 -682 -5z"
                  />
                  <path
                    d="M390 1588 c0 -651 4 -1189 8 -1196 7 -10 151 -12 688 -10 l679 3 3
1193 2 1192 -690 0 -690 0 0 -1182z"
                  />
                </g>
              </svg>
            </strong>
            <span class="menu_title">Мониторинг</span>
          </router-link>
        </li>
        <div class="line"></div>
        <li>
          <router-link to="/services">
            <strong class="icon_svg">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="45"
                height="45"
                viewBox="0 0 900.000000 906.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <g
                  transform="translate(0.000000,906.000000) scale(0.100000,-0.100000)"
                  fill="#696969"
                  stroke="none"
                >
                  <path
                    d="M3839 9037 c-70 -20 -151 -70 -186 -116 -63 -81 -78 -190 -82 -566
-1 -131 -5 -202 -13 -206 -6 -4 -34 -10 -62 -13 -248 -33 -715 -218 -1061
-421 l-129 -76 -151 149 c-286 282 -311 302 -428 323 -86 16 -157 1 -232 -49
-76 -52 -333 -290 -423 -392 -94 -107 -123 -165 -130 -251 -6 -87 11 -156 58
-228 21 -32 119 -142 219 -245 100 -102 181 -192 181 -199 0 -7 -34 -71 -77
-142 -103 -176 -219 -419 -286 -600 -53 -143 -131 -407 -144 -487 l-6 -38
-231 -1 c-354 -1 -413 -7 -477 -48 -102 -65 -157 -150 -170 -265 -11 -96 -11
-477 1 -605 12 -137 31 -174 129 -256 l76 -65 121 0 c66 0 216 -3 332 -7 l211
-6 22 -96 c79 -348 224 -712 413 -1033 47 -81 86 -151 86 -155 0 -3 -73 -81
-163 -172 -224 -229 -273 -289 -288 -360 -14 -70 -6 -182 18 -227 28 -52 252
-292 406 -435 155 -144 184 -159 306 -159 124 0 196 48 455 300 90 88 165 160
168 160 2 0 49 -29 103 -65 256 -169 676 -343 1037 -431 96 -23 118 -31 122
-48 3 -12 6 -79 6 -151 1 -193 17 -423 35 -482 19 -63 84 -138 152 -175 78
-43 160 -50 496 -46 280 4 306 5 355 25 109 44 180 126 202 236 6 32 17 182
23 334 l12 276 145 38 c268 70 620 195 620 221 0 15 -80 99 -433 454 l-338
341 -108 -23 c-273 -56 -784 -58 -1031 -5 -223 49 -529 175 -730 301 -287 180
-563 450 -746 730 -160 245 -275 529 -339 839 -36 172 -43 574 -14 789 20 152
90 394 165 570 110 259 268 489 487 711 382 388 795 605 1303 686 138 22 472
31 615 16 700 -73 1334 -459 1724 -1051 165 -251 278 -526 341 -830 28 -131
28 -144 28 -440 0 -327 -6 -386 -60 -628 l-26 -117 348 -344 c314 -310 412
-401 434 -401 28 0 190 448 249 685 18 72 36 137 40 144 6 9 77 15 272 22 401
14 452 25 527 118 67 83 67 85 67 506 l0 380 -28 57 c-20 41 -46 71 -95 111
l-67 55 -95 6 c-52 3 -203 8 -335 12 -132 3 -243 8 -246 12 -4 4 -23 72 -44
152 -96 370 -246 724 -427 1009 l-60 93 29 31 c15 18 100 106 188 197 182 187
238 264 255 346 25 121 -12 208 -162 374 -133 148 -371 366 -433 398 -59 30
-172 41 -229 22 -57 -19 -138 -84 -336 -274 -102 -97 -192 -181 -201 -186 -11
-6 -31 1 -69 27 -276 185 -758 384 -1117 459 l-83 17 0 266 c-1 377 -13 436
-115 537 -84 85 -99 87 -495 91 -247 2 -347 -1 -381 -10z"
                  />
                  <path
                    d="M4060 6200 c-163 -23 -280 -60 -280 -90 0 -16 127 -155 364 -400 266
-272 310 -328 327 -408 18 -89 7 -227 -23 -293 -53 -112 -339 -396 -453 -450
-158 -75 -314 -58 -444 49 -25 20 -145 133 -266 251 -242 236 -348 331 -369
331 -47 0 -78 -277 -57 -499 16 -164 49 -290 110 -417 156 -326 437 -590 753
-708 180 -67 279 -83 598 -95 250 -10 276 -16 385 -86 174 -111 322 -252 1760
-1685 1488 -1482 1616 -1604 1750 -1666 43 -21 65 -24 165 -24 198 0 320 43
437 154 127 119 176 246 177 456 1 141 -7 164 -96 285 -97 130 -321 359 -1633
1675 -749 751 -1399 1405 -1445 1454 -108 116 -157 185 -202 282 l-36 79 -11
315 c-15 447 -32 535 -143 753 -63 123 -157 245 -284 368 -195 188 -402 296
-670 349 -115 23 -321 33 -414 20z m4468 -5369 c74 -38 122 -105 143 -200 16
-77 -7 -145 -76 -219 -71 -77 -95 -86 -199 -80 -94 6 -128 21 -179 77 -49 54
-68 94 -74 155 -13 123 64 233 196 283 46 17 139 10 189 -16z"
                  />
                </g>
              </svg>
            </strong>
            <span class="menu_title">Сервисы</span>
          </router-link>
        </li>
        <div class="line"></div>
        <li class="invitation_wrapper">
          <router-link to="/profile">
            <strong class="icon_svg">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="45"
                height="45"
                viewBox="0 0 980.000000 980.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <g
                  transform="translate(0.000000,980.000000) scale(0.100000,-0.100000)"
                  fill="#696969"
                  stroke="none"
                >
                  <path
                    d="M4560 9714 c-19 -2 -87 -9 -150 -15 -287 -26 -691 -110 -956 -198
-383 -128 -498 -175 -779 -320 -197 -101 -388 -218 -530 -324 -56 -41 -121
-87 -145 -102 -60 -37 -171 -126 -245 -196 -33 -31 -107 -100 -165 -155 -58
-54 -152 -148 -209 -209 -57 -60 -119 -126 -139 -145 -42 -42 -187 -221 -203
-253 -17 -31 -132 -191 -141 -195 -5 -2 -8 -8 -8 -14 0 -5 -7 -16 -15 -24 -80
-80 -448 -782 -470 -896 -3 -11 -9 -26 -16 -34 -6 -8 -9 -14 -6 -14 4 0 -5
-29 -18 -64 -102 -271 -185 -583 -234 -881 -49 -296 -56 -391 -56 -780 0 -389
7 -484 56 -780 49 -299 130 -601 235 -882 13 -35 21 -63 17 -63 -3 0 0 -6 6
-14 7 -8 13 -23 16 -34 22 -114 390 -816 470 -896 8 -8 15 -19 15 -24 0 -6 3
-12 7 -14 10 -4 125 -164 142 -195 16 -32 161 -211 203 -253 20 -19 82 -85
139 -145 97 -103 186 -189 386 -375 44 -41 114 -99 154 -128 209 -151 263
-189 364 -256 200 -132 465 -275 705 -379 95 -41 315 -120 510 -183 232 -75
620 -154 915 -186 161 -17 798 -18 960 0 340 37 758 128 1040 227 88 31 192
67 230 80 259 87 745 348 1000 538 56 41 121 87 145 102 60 37 171 126 245
196 33 31 107 100 165 155 58 54 152 148 209 209 57 60 119 126 139 145 42 42
187 221 203 253 17 31 132 191 142 195 4 2 7 8 7 14 0 5 7 16 15 24 80 80 448
782 470 896 3 11 9 26 16 34 6 8 9 14 6 14 -4 0 4 28 17 63 105 281 186 583
235 882 49 296 56 391 56 780 0 389 -7 484 -56 780 -49 299 -130 601 -235 883
-13 34 -21 62 -17 62 3 0 0 6 -6 14 -7 8 -13 23 -16 34 -22 114 -390 816 -470
896 -8 8 -15 19 -15 24 0 6 -3 12 -7 14 -10 4 -125 164 -142 195 -16 32 -161
211 -203 253 -20 19 -82 85 -139 145 -97 103 -186 189 -386 375 -44 41 -114
99 -154 128 -204 147 -263 189 -364 256 -248 166 -673 382 -875 446 -30 10
-113 39 -185 64 -299 107 -653 188 -1020 232 -122 15 -771 28 -865 18z m736
-499 c316 -29 668 -101 949 -197 279 -94 612 -239 811 -353 123 -71 362 -228
429 -282 428 -348 543 -460 794 -772 42 -52 87 -106 100 -120 35 -39 221 -321
289 -441 156 -273 351 -758 427 -1060 62 -246 107 -514 126 -753 15 -194 7
-671 -15 -835 -70 -526 -211 -975 -467 -1484 -95 -190 -326 -554 -413 -653
-20 -22 -36 -46 -36 -52 0 -34 -25 -1 -101 135 -134 238 -313 491 -447 629
-31 32 -99 103 -151 158 -404 426 -1004 782 -1598 949 l-111 32 111 74 c83 55
148 111 252 215 276 276 462 603 544 954 12 51 20 100 17 107 -3 7 -1 16 5 20
6 3 9 21 7 39 -1 19 1 37 5 41 10 10 9 352 -2 358 -4 3 -6 17 -4 31 3 13 2 33
-2 42 -4 10 -9 38 -11 62 -5 57 -47 215 -86 321 -50 138 -181 386 -235 445
-10 11 -48 56 -84 100 -37 44 -118 130 -180 190 -227 221 -500 367 -828 444
-564 133 -1207 -4 -1650 -351 -88 -69 -211 -179 -211 -190 0 -4 -18 -24 -40
-44 -22 -20 -40 -42 -40 -49 0 -7 -6 -15 -14 -18 -19 -7 -103 -126 -171 -242
-113 -192 -196 -436 -230 -673 -21 -149 -16 -454 10 -597 61 -337 189 -612
404 -869 123 -146 317 -303 476 -383 l49 -25 -129 -37 c-453 -129 -922 -374
-1316 -688 -162 -129 -471 -435 -598 -592 -89 -110 -250 -330 -264 -361 -4 -8
-37 -64 -75 -124 -65 -104 -92 -135 -92 -104 0 8 -11 24 -25 36 -57 49 -258
342 -362 527 -166 293 -347 751 -427 1075 -87 356 -116 579 -123 958 -8 449
27 764 132 1182 76 302 271 787 427 1060 68 120 254 402 289 441 13 14 58 68
100 120 251 312 366 424 794 772 67 54 306 211 429 282 273 156 759 352 1067
430 498 127 986 166 1495 120z"
                  />
                </g>
              </svg>
            </strong>
            <span class="menu_title">Кабинет</span>
          </router-link>
        </li>
        <div class="line"></div>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"; //импортируем все геттеры и экшены из хранилища
export default {
  name: "SideBarComponent",
  components: {},
  data() {
    return {
      inter: null,
    };
  },
  methods: {
    ...mapActions(["fetchInvitations"]), //метод из actions из хранилища (запрос на сервер за массивом приглашений)
  },
  computed: {
    //получаем пользователя из хранилища
    getUser() {
      return this.$store.getters.getUser;
    },
    //следим за изменением URL
    currentPath() {
      return this.$route.name;
    },
  },

  watch: {
    currentPath(newPath) {
      localStorage.setItem("currentPath", newPath);
    },
  },
};
</script>

<style scoped>
.line {
  width: 100%;
  border-bottom: 1px solid #56565683;
}
.logo {
  fill: var(--logo-color);
  font-size: 20px;
  font-weight: 900;
  color: var(--logo-color);
  vertical-align: middle;
  margin: 0 auto;
}

.icon_svg {
  margin: auto;
  display: flex;
}
.router-link-active {
  border-left: 3px solid var(--header-color);
  /* background-color: #14141459; */
}
.router-link-active svg g {
  /* fill: var(--active-text-color); */
  /* fill: var(--navbar-svg-color); */
  /* fill: var(--header-color); */
  border-left: 3px solid var(--active-text-color);
}
.menu {
  overflow: inherit;
  width: 110px;
  position: fixed;
  top: 85px;
  border-radius: 1px 15px 0 0;
  bottom: 0;
  z-index: 998;
}

#container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#container.active {
  display: flex;
}
.top_menu {
  top: 0;
}

#container .brand {
  display: flex;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  margin: 10px 0;
  align-items: center;
  justify-content: space-between;
}
#container.active .brand {
  width: 100%;
  align-items: center;
  justify-content: center;
}

#container .brand .logo ion-icon {
  display: flex;
  color: #191919;
  font-size: 20px;
}
#container .brand h3 {
  display: flex;
  font-size: 20px;
  color: #191919;
}
#container .brand a {
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
}
#container.active .brand a,
#container.active .brand a ion-icon {
  display: flex;
  width: 80%;
}

#container .brand a ion-icon {
  display: flex;
  color: #191919;
  font-size: 30px;
  background: transparent;
}
#container .navbar {
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
}
#container.active .navbar ul {
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: center;
}
#container .navbar ul {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
}
#container .navbar ul li {
  display: flex;
  height: 110px;
  width: 100%;
  /* margin: 15px 0; */
  /* border-radius: 7px; */
}
#container .navbar ul li a {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  text-decoration: none;
  align-items: center;
  justify-content: left;
}
#container.active .navbar ul li a {
  align-items: center;
  justify-content: center;
}

#container .navbar ul li a span {
  display: flex;
  font-size: 12px;
  color: #696969;
  border-radius: 7px;
}
#container .navbar ul li a ion-icon {
  display: flex;
  font-size: 20px;
  margin: 0 15px;
}
#container .navbar ul li a:hover,
#container .navbar ul li a:hover span,
#container .navbar ul li a:hover ion-icon {
  color: var(--active-text-color);
}
#container .navbar ul li a:hover {
  background-color: #60606038;
  transition: 0.4s;
}

.invitation_wrapper {
  position: relative;
}

.invitation {
  fill: #f40a0a;
  /* font-size: var(--middle-size); */
  /* width: 10px;
  height: 10px; */
  /* border-radius: 10px; */
  position: absolute;
  top: 5px;
  right: 5px;
}

@media screen and (max-width: 600px) {
  .router-link-active {
    border-left: none;
    border-radius: 0;
    border-bottom: 2px solid var(--header-color);
  }
  #container {
    overflow: hidden;
  }
  #container .navbar {
    margin: auto;
  }
  #container .navbar ul {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    justify-content: space-around;
  }
  #container .navbar ul li {
    width: 40px;
    height: 40px;
  }
  #container .navbar {
    margin-top: 10px;
  }
  svg {
    width: 30px;
    height: 30px;
  }
  .menu {
    border-radius: 0;
    width: 100%;
    position: fixed;
    /* top: 92vh; */
    top: 90%;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .line {
    display: none;
  }
  #container .navbar ul li a span {
    display: none;
  }
  #container .navbar ul li a:hover {
    background-color: var(--nav-bg-color);
    transition: 0;
  }
}

@media screen and (max-width: 500px) {
  #container.active {
    width: 55px;
  }
}
</style>
