<template>
  <div class="brand">
    <svg
      class="logo"
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="150"
      height=""
      viewBox="0 0 1783.000000 412.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,412.000000) scale(0.100000,-0.100000)"
        fill="#ffffff"
        stroke="none"
      >
        <path
          d="M522 4045 c-110 -31 -206 -67 -250 -92 -103 -61 -187 -172 -231 -307
-15 -47 -16 -185 -13 -1590 l4 -1539 22 -87 c26 -100 74 -177 155 -253 62 -58
143 -98 271 -132 l95 -25 3703 0 c2036 0 3702 3 3702 7 0 3 -18 21 -39 40 -57
49 -129 147 -168 230 l-34 73 -3577 2 -3577 3 -47 28 c-26 15 -59 43 -74 63
-61 80 -58 1 -62 1594 -2 1298 -1 1459 13 1496 18 49 56 87 110 111 38 17 195
18 3631 21 l3592 2 5 30 c18 89 110 219 212 300 l38 30 -1540 0 c-847 0 -2518
2 -3714 5 -1921 5 -2180 4 -2227 -10z"
        />
        <path
          d="M8385 4034 c-184 -45 -350 -182 -435 -358 -63 -132 -60 -50 -60
-1637 0 -1406 1 -1448 20 -1523 46 -182 177 -345 340 -424 158 -78 16 -72
1845 -72 1829 0 1687 -6 1845 72 163 79 294 242 340 424 19 75 20 117 20 1519
0 1402 -1 1444 -20 1519 -46 182 -177 345 -340 424 -158 78 -15 72 -1850 71
-1399 -1 -1654 -3 -1705 -15z m1795 -355 c55 -70 147 -256 234 -474 l75 -191
-35 -109 c-141 -443 -292 -1133 -335 -1533 -6 -62 -13 -111 -14 -110 -2 2 -10
64 -19 138 -56 453 -194 1076 -337 1518 l-30 93 66 167 c79 198 191 428 240
495 60 80 95 81 155 6z m-772 -352 c38 -45 158 -434 187 -602 l17 -100 -50
-195 c-103 -397 -123 -727 -67 -1090 36 -232 128 -532 236 -774 27 -60 47
-111 45 -114 -10 -9 -109 95 -150 157 -88 134 -209 397 -316 686 -73 197 -106
266 -165 351 -79 111 -229 231 -360 289 -63 28 -60 21 -75 190 -22 256 -30
500 -21 590 11 107 17 125 42 125 33 0 96 -109 209 -365 151 -341 201 -409
231 -318 13 42 8 267 -11 480 -7 67 -10 175 -8 240 3 112 5 122 40 195 41 87
101 179 151 232 37 39 49 43 65 23z m1467 -29 c87 -102 165 -246 184 -339 12
-55 8 -156 -19 -489 -19 -233 -9 -350 30 -350 32 0 82 90 195 345 129 294 199
405 232 373 38 -38 39 -292 4 -688 l-16 -184 -85 -44 c-184 -95 -321 -226
-400 -382 -16 -32 -58 -136 -95 -233 -152 -409 -290 -686 -394 -794 -46 -47
-88 -75 -78 -52 3 8 31 71 61 141 190 435 281 918 247 1313 -16 181 -35 290
-93 515 -40 159 -48 204 -43 245 18 138 141 561 185 633 11 17 26 32 34 32 8
0 31 -19 51 -42z m-2195 -265 c0 -10 -3 -28 -6 -40 l-6 -23 -329 0 -329 0 0
40 0 40 335 0 c312 0 335 -1 335 -17z m374 -10 c-3 -16 -7 -34 -10 -40 -2 -9
-26 -13 -73 -13 -69 0 -69 0 -101 40 l-32 40 111 0 111 0 -6 -27z m2307 10
c-33 -57 -46 -63 -125 -63 -42 0 -76 4 -76 9 0 5 -3 23 -6 40 l-6 31 111 0
c104 0 111 -1 102 -17z m859 -23 l0 -40 -339 0 -339 0 -6 23 c-16 61 -40 57
339 57 l345 0 0 -40z m-3630 -810 l0 -40 -75 0 c-66 0 -84 4 -145 35 -38 19
-74 35 -80 35 -5 0 -10 -16 -10 -35 l0 -35 -135 0 -135 0 0 40 0 40 290 0 290
0 0 -40z m3630 0 l0 -40 -145 0 -145 0 0 35 c0 19 -5 35 -10 35 -6 0 -42 -16
-80 -35 -61 -31 -79 -35 -145 -35 l-75 0 0 40 0 40 300 0 300 0 0 -40z m-3744
-251 c55 -22 134 -59 177 -81 l77 -40 0 -72 c0 -83 21 -221 50 -322 81 -293
327 -683 624 -991 54 -57 96 -103 92 -103 -28 0 -235 126 -351 214 -101 76
-269 239 -348 336 -208 258 -359 582 -412 884 -13 80 -21 216 -11 216 2 0 48
-18 102 -41z m3364 -35 c0 -159 -69 -403 -175 -623 -113 -233 -236 -402 -422
-581 -155 -150 -314 -265 -483 -350 l-65 -32 106 108 c415 427 679 936 679
1308 l0 81 63 35 c58 32 273 128 290 129 4 1 7 -33 7 -75z m-3442 -553 c7 -21
12 -40 12 -42 0 -2 -94 -3 -210 -1 l-210 3 0 39 0 40 198 0 199 0 11 -39z
m3812 -1 l0 -40 -206 0 c-184 0 -205 2 -200 16 3 9 9 27 12 40 l6 24 194 0
194 0 0 -40z m-3407 -695 l38 -45 -425 0 -426 0 0 45 0 45 388 0 387 -1 38
-44z m3407 0 l0 -45 -422 0 -422 0 44 45 44 45 378 0 378 0 0 -45z"
        />
        <path
          d="M12182 4041 c2 -5 29 -30 60 -55 63 -51 146 -169 174 -247 l18 -49
2420 0 c1635 0 2433 -3 2458 -10 52 -15 103 -66 120 -120 11 -37 13 -315 13
-1546 l0 -1500 -27 -41 c-19 -28 -45 -50 -85 -70 l-58 -28 -2426 -3 -2427 -2
-11 -33 c-46 -126 -113 -217 -216 -294 l-30 -22 2517 0 c2751 -1 2599 -4 2777
55 95 32 151 65 216 129 50 48 108 144 126 209 14 48 12 3135 -2 3193 -46 192
-188 340 -401 415 l-79 28 -2571 0 c-1496 0 -2568 -4 -2566 -9z"
        />
        <path
          d="M15843 3295 c-152 -42 -243 -143 -243 -266 0 -64 36 -143 86 -189 59
-54 102 -71 178 -71 78 0 108 10 202 62 42 23 75 37 73 32 -2 -6 -38 -41 -79
-80 -197 -182 -360 -263 -597 -298 -59 -9 -109 -18 -111 -20 -2 -2 0 -382 4
-844 l7 -841 321 0 321 0 0 351 0 351 52 18 c98 34 178 106 213 190 7 16 16
30 21 30 5 0 9 -207 9 -470 l0 -470 315 0 315 0 0 1240 0 1240 -326 0 -326 0
4 -60 c1 -33 3 -60 2 -60 0 0 -25 24 -55 53 -78 76 -153 110 -254 114 -50 2
-100 -3 -132 -12z m447 -879 l0 -415 -35 15 c-58 24 -120 18 -157 -14 -26 -24
-31 -36 -31 -73 0 -24 9 -72 21 -105 17 -49 19 -69 11 -97 -9 -32 -58 -87 -79
-87 -5 0 -15 -3 -24 -6 -14 -6 -16 37 -16 435 0 518 -9 475 127 598 47 43 105
97 127 119 23 23 44 42 49 43 4 0 7 -186 7 -413z"
        />
        <path
          d="M910 2020 l0 -1240 745 0 745 0 0 569 c0 443 -3 571 -12 578 -7 6
-78 20 -158 32 -119 19 -181 35 -343 92 -110 38 -210 69 -222 69 -37 0 -38 17
-2 24 18 3 75 32 127 63 199 118 409 201 537 210 l73 6 0 412 c0 367 -2 414
-16 419 -9 3 -344 6 -745 6 l-729 0 0 -1240z m834 1034 c14 -5 16 -46 16 -355
l0 -349 -32 -20 c-42 -25 -204 -96 -240 -105 l-28 -7 0 421 0 421 134 0 c74 0
141 -3 150 -6z m-58 -1086 l74 -53 0 -392 0 -393 -150 0 -150 0 0 445 0 445
76 0 c75 0 76 0 150 -52z"
        />
        <path
          d="M2660 2821 l0 -438 128 -6 c90 -4 147 -12 196 -27 113 -36 298 -118
350 -156 l48 -36 -28 -14 c-15 -8 -49 -34 -74 -58 -99 -93 -235 -133 -512
-151 l-98 -7 0 -574 0 -574 315 0 315 0 0 534 0 534 28 17 c15 9 63 55 107
101 65 67 86 84 109 84 l29 0 -6 -635 -7 -635 285 0 285 0 0 1240 0 1240 -735
0 -735 0 0 -439z m910 -201 c0 -236 -1 -430 -3 -430 -10 0 -237 126 -264 147
l-33 24 0 345 0 344 150 0 150 0 0 -430z"
        />
        <path
          d="M4428 3229 l-48 -31 0 -314 c0 -172 4 -315 8 -318 4 -2 136 -6 292
-7 l285 -2 5 204 5 204 137 3 136 3 7 -93 c3 -51 5 -189 3 -307 l-3 -214 -177
-111 c-98 -61 -178 -113 -178 -116 0 -3 75 -50 168 -105 l167 -100 3 -365 2
-365 -140 3 -140 3 0 245 0 244 -310 0 -310 0 0 -387 1 -388 58 -63 58 -62
486 0 486 0 -5 73 -6 72 27 -45 c50 -85 105 -125 188 -136 90 -13 204 43 244
119 22 43 19 135 -6 191 -15 36 -29 50 -58 62 -89 38 -224 -12 -269 -99 -17
-32 -34 -35 -34 -7 0 77 137 182 278 214 l57 13 0 362 c0 200 -3 365 -7 367
-5 3 -90 11 -191 18 l-182 12 -102 65 c-56 35 -102 66 -102 69 0 3 51 37 114
77 l115 72 151 10 c83 6 162 11 176 11 l26 0 -8 407 -7 407 -61 68 -61 68
-616 0 -615 0 -47 -31z"
        />
        <path
          d="M6110 2020 l0 -1240 740 0 740 0 0 683 0 684 -157 11 c-91 7 -192 20
-238 32 -112 29 -231 91 -304 156 -52 48 -61 61 -61 91 0 26 8 43 32 66 l32
32 6 -31 c18 -84 81 -137 164 -137 60 0 103 20 139 63 70 82 31 226 -76 282
-34 18 -58 23 -127 23 -72 0 -94 -4 -144 -28 -33 -15 -77 -46 -98 -69 l-38
-41 0 331 0 332 -305 0 -305 0 0 -1240z m753 126 l117 -76 0 -450 0 -450 -130
0 -130 0 0 531 c0 427 3 530 13 526 7 -2 65 -39 130 -81z"
        />
        <path
          d="M13517 3253 c-4 -3 -7 -126 -7 -272 l0 -267 -40 4 -40 4 0 -40 0 -40
-52 29 c-126 69 -267 89 -343 46 -112 -62 -111 -250 1 -330 41 -29 121 -45
168 -33 14 3 49 27 76 51 28 25 55 43 61 40 18 -12 10 -91 -14 -126 -63 -94
-292 -157 -642 -177 l-120 -7 0 -675 0 -675 333 -3 332 -2 0 639 0 639 33 13
c17 7 79 60 137 118 l105 106 6 -150 c4 -82 7 -423 8 -757 l1 -608 310 0 310
0 2 648 3 647 80 48 c120 71 196 122 232 156 18 17 34 31 37 31 4 0 6 -344 6
-765 l0 -765 325 0 325 0 0 1240 0 1240 -335 0 -335 0 0 -332 0 -333 -102
-100 c-102 -101 -173 -156 -215 -169 l-23 -7 -2 468 -3 468 -306 3 c-168 1
-308 -1 -312 -5z"
        />
      </g>
    </svg>
    <div class="sub_brand">
      <div class="str">УМНАЯ</div>
      <div class="str">ПРАЧЕЧНАЯ</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LogoComp",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.logo {
  margin-left: 22px;
  color: var(--nav-bg-color);
  vertical-align: middle;
}
.brand {
  display: flex;
  padding: 0 20px;
  align-items: center;
}
.sub_brand {
  display: flex;
  flex-direction: column;
  margin-left: 2px;
}
.str {
  margin: 0;
  padding: 0;
  font-size: 12px;
  height: 15px;
  color: #ffffff;
  font-weight: 700;
}
@media screen and (max-width: 600px) {
  .logo {
    width: 80px;
  }
  .sub_brand {
    display: none;
  }
  .str {
    font-size: 10px;
  }
}
</style>
