<template>
  <transition name="slide-fade">
    <div @click="modalClose" v-if="isModal" class="overlay">
      <div @click.stop="" class="update_message">
        <div class="title_section">
          <div class="header_news">
            <div>Уведомления</div>
            <div @click.stop="modalClose" class="button-close">×</div>
          </div>
        </div>

        <!-- TABS -->
        <Tabs
          :names="tabs"
          :selectedTab="selectedTab"
          @changeTab="changeTab"
        >
          <!-- ОШИБКИ (в главном блоке уведомлений) -->
          <div class="tab_wrapper" v-if="selectedTab === 'Все ошибки'">
            <div class="news_box">
              <div
                v-for="err in allUnreadErrorsList"
                :key="err.id"
                class="news-item box_wrapper"
              >
                <div class="header_section">
                  <div class="err_label">{{ err.name }}</div>
                  <div class="date_news">{{ convertDate(err.err_date) }}</div>
                </div>
                <div class="err_desc">
                  <div class="date_name">
                    Цикл стирки:
                    <span class="str_content">{{ err.w_cycle }}</span>
                  </div>
                  <div class="date_name">
                    Программа:
                    <span class="str_content">{{ err.program_name }}</span>
                  </div>
                  <div class="date_name">
                    Шаг: <span class="str_content">{{ err.program_step }}</span>
                  </div>
                </div>

                <div v-if="!err.isViewed" class="button_section">
                  <span @click="goToDevice(err.device_id)" class="link"
                    >Перейти к устройству</span
                  >
                  <button @click="setViewError(err.id)" class="btn">
                    Прочитать
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- НОВОСТИ -->
          <div v-if="selectedTab === 'Новости'">
            <MarkDown :content="unreadedNews" />
          </div>
          <!-- ПРИГЛАШЕНИЯ -->
          <div v-if="selectedTab === 'Приглашения'">
            <!-- УВЕДОМЛЕНИЯ -->
          <transition appear name="slide-fade">
            <div v-if="invitations?.length !== 0">
              <div class="active_tab">
                Количество приглашений: {{ invitations.length }}
              </div>
              <div
                class="invit_item"
                v-for="invit in invitations"
                :key="invit.ID"
              >
                <div class="box_wrapper">
                  <div class="invit_message">
                    Вас приглашают в организацию
                    <span class="org_name">{{ invit.org_name }}</span>
                  </div>
                  <div class="btn_wrapper__answer">
                    <button @click="answerSend(invit.ID, false)" class="btn">
                      Отклонить
                    </button>
                    <button @click="answerSend(invit.ID, true)" class="btn">
                      Принять
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>Уведомлений нет</div>
          </transition>
          <!-- УВЕДОМЛЕНИЯ -->

          </div>
        </Tabs>
      </div>
    </div>
  </transition>
</template>

<script>
import Tabs from "@/components/Tabs/TabsApp.vue";
import { answerSend } from "@/api/api";
import { convertDate } from "@/utils";
import MarkDown from "@/components/MarkDown.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "NewsComp",
  components: { Tabs, MarkDown },
  props: {
    unreadedNews: {
      type: Array,
      required: true,
      default: () => [],
    },
    isModal: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  emits: ["close-news-window"],

  data() {
    return {
      errMessage: "",
      errReadNews: "",

      selectedTab: "Новости",
      tabs: [{ name: "Новости" }, { name: "Все ошибки" }, { name: "Приглашения" }],
    };
  },
  methods: {
    convertDate,
    ...mapActions([
      "fetchAllUnreadErrors",
      "newErrorIsView",
      "setActiveDevice",
      "fetchInvitations"
    ]),
    async answerSend(orgId, answer) {
      try {
        //отправляем свое согласие/отказ как сотрудника в организацию
        const response = await answerSend(orgId, answer);
        //...и перезаписываем данные (массив) в хранилище, что бы не перезагружать страницу
        //(за массивом следим в этом компоненте(ProfileComp) и в SideBarComp)
        if (response.status === 200) {
          this.fetchInvitations(); // делаем перезапрос и перезапись обновленного списка приглашений
        }
      } catch (error) {
        console.log(error);
      }
    },
    goToDevice(device_id) {
      // "Перейти к устройству".
      // Записываем id устройства в хранилище, что бы сделать его активным при нажатии кнопки "Перейти к устройству".
      if (device_id) {
        this.setActiveDevice(device_id);
        // Проверяем, находимся ли мы уже на маршруте /devices, так как this.$router.push("/devices") не срабатывает если мы уже находимся по адресу "/devices"
        if (this.$route.path === "/devices") {
          // this.$router.go(0); // Перезагружаем страницу
          this.$emit("close-news-window");
        } else {
          this.$router.push("/devices"); // Переходим на маршрут /devices
        }
        this.$emit("close-news-window");
      } else {
        console.error("ID устройства не найдено");
      }
    },
    setViewError(err_id) {
      // Делаем ошибку прочитанной
      this.newErrorIsView(err_id);
    },
    changeTab(tabName) {
      this.selectedTab = tabName;
    },

    modalClose() {
      // Закрываем модальное окно с непрочитанными уведомлениями
      this.$emit("close-news-window", false);
    },
  },

  computed: {
    ...mapGetters(["allUnreadErrorsList", "getActiveDevice", "invitations"]),
    newsListLength() {
      return this.unreadedNews.length;
    },
  },
  mounted() {
    this.fetchAllUnreadErrors(); //Получаем все непрочитанные ошибки (Vuex module errorsNotification)
    this.fetchInvitations(); // Получаем приглашения в организацию
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Затемнение фона */
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Выравнивание элементов по началу страницы */
  padding-top: 100px; /* Расстояние сверху */
  z-index: 998; /* Положение поверх всего остального */
}
.link {
  color: #646464;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: end;
}
.link:hover {
  text-decoration: underline;
}
.update_message {
  position: relative;
  width: 80%;
  background-color: var(--nav-bg-color);
  padding: 20px;
  padding-top: 0;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  z-index: 999;
  overflow: hidden; /* Добавляем это, чтобы контент не выходил за пределы блока */
}
.active_tab {
  color: var(--active-text-color);
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: 550;
  border-bottom: 2px solid var(--active-text-color);
  border-radius: 0;
  font-size: var(--middle-size);
}
.invit_item {
  margin-top: 15px;
}
.box_wrapper {
  min-height: 100px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.org_name {
  color: var(--header-color);
}

.btn_wrapper__answer {
  display: flex;
  justify-content: end;
  gap: 15px;
}

.news_box {
  max-height: calc(60vh - 50px); /* Учтем padding и заголовок */
  overflow-y: auto; /* Включение вертикальной прокрутки */
}

.news_box::-webkit-scrollbar {
  width: 10px;
  background-color: none;
}

.news_box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--active-text-color);
}

.news_box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: none;
  margin-top: 15px;
  margin-bottom: 15px;
}

.line {
  border-top: 1px solid rgb(197, 196, 196);
  margin-bottom: 15px;
}

.header_news {
  display: flex;
  justify-content: space-between;
}

.readed_btn {
  display: flex;
  justify-content: end;
  margin-top: 15px;
}

.date_news {
  color: #646464;
}

.button-close {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 24px;
  z-index: 998;
}

.news-item {
  margin: 15px 3px;
}
.box_wrapper {
  min-height: auto;
}
.date_name {
  margin-right: 20px;
  color: #696969;
}

.news-item::-webkit-scrollbar {
  width: 10px;
  background-color: none;
}

.news-item::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--active-text-color);
}

.news-item::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: none;
  margin-top: 15px;
  margin-bottom: 15px;
}
/* Убрать полосу прокрутки */
.news-item {
  scrollbar-width: none; /* Для Firefox */
  -ms-overflow-style: none; /* Для Internet Explorer и Edge */
}

.news-item::-webkit-scrollbar {
  display: none; /* Для Chrome, Safari и Opera */
}
.header_section {
  display: flex;
  justify-content: space-between;
}
.err_label {
  font-weight: 600;
}

.date_news {
  color: #646464;
}
.err_desc {
  display: flex;
  flex-wrap: wrap;
}
.date_name {
  margin-right: 20px;
  color: #696969;
}
.str_content {
  text-decoration: underline;
}
.button_section {
  display: flex;
  justify-content: space-between;
}

.btn {
  margin: 15px 0 0 0;
}
.display_none {
  display: none;
}
@media (max-width: 730px) {
  .btn {
    height: 30px;
  }
}
@media (max-width: 430px) {
  .btn {
    font-size: var(--min-size);
  }
}

</style>
