// Модуль с ТЕКУЩИМИ ПОКАЗАТЕЛЯМИ ТО 

import { getCurrentMaintenance, getThresholdMaintenance } from "@/api/api";

export default {
  state: {
    // Блок с ТЕКУЩИМИ показателями ТО
    monthly_counter: null, // Ежемесячные
    quarterly_counter: null, // Квартальные
    yearly_counter: null, // Годовые

    // Блок с ПОРОГОВЫМИ показателями ТО
    monthly_threshold: null, //Ежемесячные
    quarterly_threshold: null, // Квартальные
    yearly_threshold: null, // Годовые

    //Итоговый счетчики ТО (Годовой,Квартальный,Месячный)
    counterMonthly: null, // Ежемесячные
    counterQuarterly: null, // Квартальные
    counterAnnual: null, // Годовые
  },
  actions: {
    async fetchMetrics({ dispatch, commit }, deviceId) {
      //Получаем данные текущих и пороговых показателей ТО
      
      try {
        // Текущие данные о ТО
        const responseCurrentMaintenance = await getCurrentMaintenance(
          deviceId
        );
        // Порог ТО
        const responseThresholdMaintenance = await getThresholdMaintenance(
          deviceId
        );

        const currentMaintenance = responseCurrentMaintenance?.data.metrics; // фиксируем массив с ТЕКУЩИМИ ДАННЫМИ ТО в data
        const thresholdMaintenance = responseThresholdMaintenance?.data.metrics; // фиксируем массив ПОРОГОВОГО ТО в data
        commit("SET_MONTHLY_COUNTER", currentMaintenance[0]?.value);
        commit("SET_QUATERLY_COUNTER", currentMaintenance[1]?.value);
        commit("SET_YEARLY_COUNTER", currentMaintenance[2]?.value);
        commit("SET_MONTHLY_THRESHOLD", thresholdMaintenance[0]?.value);
        commit("SET_QUATERLY_THRESHOLD", thresholdMaintenance[1]?.value);
        commit("SET_YEARLY_THRESHOLD", thresholdMaintenance[2]?.value);

        dispatch("calculateCounters", {
          currentMaintenance,
          thresholdMaintenance,
        });
      } catch (error) {
        console.log(error.message);
      }
    },
    calculateCounters(
      { commit },
      { currentMaintenance, thresholdMaintenance }
    ) {
      //Ф-ия расчитывает итоговые значени ТО (через сколько часов будет ТО)
      // и устанавливает значения в state.
      // Текущее значения часов
      const annual = currentMaintenance.filter(
        (p) => p.register === "maintenance_yearly_counter"
      );
      const quarterly = currentMaintenance.filter(
        (p) => p.register === "maintenance_quarterly_counter"
      );
      const monthly = currentMaintenance.filter(
        (p) => p.register === "maintenance_monthly_counter"
      );

      // Пороговые значения ТО
      const thresholdAnnual = thresholdMaintenance.filter(
        (p) => p.register === "maintenance_yearly_threshold"
      );
      const thresholdQuarterly = thresholdMaintenance.filter(
        (p) => p.register === "maintenance_quarterly_threshold"
      );
      const thresholdMonthly = thresholdMaintenance.filter(
        (p) => p.register === "maintenance_monthly_threshold"
      );

      const resultAnnual = +thresholdAnnual[0]?.value - +annual[0]?.value;
      const resultQuarterly =
        +thresholdQuarterly[0]?.value - +quarterly[0]?.value;
      const resultMonthly = +thresholdMonthly[0]?.value - +monthly[0]?.value;

      // Вызываем мутации для обновления состояния.
      commit("SET_COUNTER_MONTHLY", resultMonthly);
      commit("SET_COUNTER_QUARTERLY", resultQuarterly);
      commit("SET_COUNTER_ANNUAL", resultAnnual);
    },
    deleteAllMetrics({ commit }) {
      // Экшены для удаления всех значений(нужно при размонтировании компонента DeviceComp (при переключении пункта меню),
      // что бы значения не оставались в хранилище)
      commit("SET_MONTHLY_COUNTER", null);
      commit("SET_QUATERLY_COUNTER", null);
      commit("SET_YEARLY_COUNTER", null);
      commit("SET_MONTHLY_THRESHOLD", null);
      commit("SET_QUATERLY_THRESHOLD", null);
      commit("SET_YEARLY_THRESHOLD", null);
      commit("SET_COUNTER_MONTHLY", null);
      commit("SET_COUNTER_QUARTERLY", null);
      commit("SET_COUNTER_ANNUAL", null);
      // commit
    },
  },
  mutations: {
    //Установка текущих показателей ТО (Ежемесячный, квартальный, годовой)
    SET_MONTHLY_COUNTER(state, value) {
      state.monthly_counter = value;
    },
    SET_QUATERLY_COUNTER(state, value) {
      state.quarterly_counter = value;
    },
    SET_YEARLY_COUNTER(state, value) {
      state.yearly_counter = value;
    },

    // Установка пороговых показателей ТО (Ежемесячный, квартальный, годовой)
    SET_MONTHLY_THRESHOLD(state, value) {
      state.monthly_threshold = value;
    },
    SET_QUATERLY_THRESHOLD(state, value) {
      state.quarterly_threshold = value;
    },
    SET_YEARLY_THRESHOLD(state, value) {
      state.yearly_threshold = value;
    },

    // Итоговый счетчик ТО (Годовой,Квартальный,Месячный)
    SET_COUNTER_MONTHLY(state, value) {
      state.counterMonthly = value;
    },
    SET_COUNTER_QUARTERLY(state, value) {
      state.counterQuarterly = value;
    },
    SET_COUNTER_ANNUAL(state, value) {
      state.counterAnnual = value;
    },
  },

  getters: {
    // Блок с ТЕКУЩИМИ показателями ТО
    getMonthly_counter: (state) => {
      if (state.monthly_counter === 0) return "0";
      return state.monthly_counter;
    },
    getQuarterly_counter: (state) => {
      if (state.quarterly_counter === 0) return "0";
      return state.quarterly_counter;
    },
    getYearly_counter: (state) => {
      if (state.yearly_counter === 0) return "0";
      return state.yearly_counter;
    },

    // Блок с ПОРОГОВЫМИ показателями ТО
    getMonthly_threshold: (state) => {
      if (state.monthly_threshold === 0) return "0";
      return state.monthly_threshold;
    },
    getQuarterly_threshold: (state) => {
      if (state.quarterly_threshold === 0) return "0";
      return state.quarterly_threshold;
    },
    getYearly_threshold: (state) => state.yearly_threshold,

    //Итоговый счетчики ТО (Годовой,Квартальный,Месячный)
    counterMonthly: (state) => {
      if (state.counterMonthly === 0) return "0";
      return state.counterMonthly;
    },
    counterQuarterly: (state) => {
      if (state.counterQuarterly === 0) return "0";
      return state.counterQuarterly;
    },
    counterAnnual: (state) => {
      if (state.counterAnnual === 0) return "0";
      return state.counterAnnual;
    },
  },
};
