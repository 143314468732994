import { createRouter, createWebHistory } from "vue-router";
import { authGuard, recoveryPasswordGuard } from "@/guards/beforeEnterGuard";
import { checkUserMe } from "@/api/api";

const routes = [
  {
    alias: "/",
    path: "/auth",
    name: "auth",
    component: () => import("@/views/AuthComp.vue"),
    beforeEnter: async (to, from, next) => {
      try {
        const user = await checkUserMe();
        if (user.data.username) {
          const currentPath = localStorage.getItem("currentPath");
          if (currentPath) {
            next({ name: currentPath });
          } else {
            next({ name: "devices" });
          }
        }
      } catch (error) {
        next();
      }
    },
  },
  {
    path: "/recover-password",
    name: "recover-password",
    component: () => import("@/views/RecoveryPassword.vue"),
    beforeEnter: recoveryPasswordGuard
  },
  {
    path: "/restore",
    name: "restore",
    component: () => import("@/views/RestorePassword.vue"),
    //если пользователь залогинен, то ему на "/restore" нет доступа
    beforeEnter: recoveryPasswordGuard
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/RegisterComp.vue"),
  },
  {
    path: "/devices",
    name: "devices",
    component: () => import("@/views/DevicesComp.vue"),
    // meta: { requiresAuth: true }
    beforeEnter: authGuard,
  },
  {
    path: "/analytics",
    name: "analytics",
    component: () => import("@/views/AnalyticsComp"),
    // meta: { requiresAuth: true }
    beforeEnter: authGuard,
  },
  {
    path: "/monitoring",
    name: "monitoring",
    component: () => import("@/views/Monitoring.vue"),
    // meta: { requiresAuth: true }
    beforeEnter: authGuard,
  },

  {
    path: "/services",
    name: "services",
    component: () => import("@/views/ServicesComp.vue"),
    // meta: { requiresAuth: true }
    beforeEnter: authGuard,
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/SettingsComp.vue"),
    // meta: { requiresAuth: true }
    beforeEnter: authGuard,
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/ProfileComp.vue"),
    // meta: { requiresAuth: true }
    beforeEnter: authGuard,
  },
  {
    path: "/:catchAll(.*)",
    name: "not-found",
    component: () => import("@/views/NotFound.vue"),
    // meta: { requiresAuth: true }
    beforeEnter: authGuard,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
