<template>
  <div class="main" :class="themeClass">
    <div class="header_wrapper">
      <div class="header">
        <LogoComp />
        <div class="theme_picker">
          <!-- BellComp -->
          <div v-if="getUser" @click="openNotificationsModal" class="bell">
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.7491 9.70957V9.00497C18.7491 5.13623 15.7274 2 12 2C8.27256 2 5.25087 5.13623 5.25087 9.00497V9.70957C5.25087 10.5552 5.00972 11.3818 4.5578 12.0854L3.45036 13.8095C2.43882 15.3843 3.21105 17.5249 4.97036 18.0229C9.57274 19.3257 14.4273 19.3257 19.0296 18.0229C20.789 17.5249 21.5612 15.3843 20.5496 13.8095L19.4422 12.0854C18.9903 11.3818 18.7491 10.5552 18.7491 9.70957Z"
                stroke-width="1.5"
              />
              <path
                d="M7.5 19C8.15503 20.7478 9.92246 22 12 22C14.0775 22 15.845 20.7478 16.5 19"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
            <div class="count_news">{{ totalNotifCount }}</div>
          </div>
          <!-- BellComp -->
          <div class="them_logo image_app">
            <svg
              class="svg_app"
              version="1.1"
              id="Capa_1"
              width="15px"
              height="15px"
              viewBox="0 0 475.465 475.465"
            >
              <g>
                <g>
                  <path
                    d="M320.535,320.229c10.701-10.701,19.107-23.173,24.986-37.071c6.095-14.411,9.186-29.694,9.186-45.426
                c0-15.732-3.091-31.015-9.186-45.426c-5.879-13.898-14.285-26.371-24.986-37.071c-10.7-10.701-23.173-19.107-37.071-24.986
                c-14.411-6.095-29.694-9.186-45.426-9.186c-15.73,0-31.014,3.091-45.425,9.186c-13.898,5.878-26.371,14.285-37.071,24.986
                c-10.701,10.701-19.107,23.173-24.985,37.071c-6.096,14.411-9.186,29.695-9.186,45.426c0,15.731,3.09,31.015,9.186,45.426
                c5.878,13.898,14.285,26.37,24.985,37.071s23.173,19.107,37.071,24.985c14.412,6.096,29.695,9.187,45.425,9.187
                c15.732,0,31.015-3.091,45.426-9.187C297.362,339.337,309.835,330.931,320.535,320.229z M238.038,163.903
                c40.776,0,73.83,33.054,73.83,73.829c0,40.774-33.054,73.829-73.83,73.829c-40.774,0-73.828-33.055-73.828-73.829
                C164.209,196.958,197.264,163.903,238.038,163.903z"
                  />
                </g>
                <g>
                  <path
                    d="M238.705,377.589c-11.798,0-21.381,9.546-21.419,21.354l-0.17,54.535c-0.038,11.83,9.523,21.449,21.353,21.486
                  c0.023,0,0.045,0,0.068,0c11.799,0,21.382-9.546,21.419-21.354l0.171-54.535c0.037-11.83-9.523-21.45-21.354-21.486
                  C238.75,377.589,238.727,377.589,238.705,377.589z"
                  />
                </g>
                <g>
                  <path
                    d="M237.366,97.876c0.058,0,0.118,0,0.177,0c11.83-0.096,21.341-9.763,21.247-21.593l-0.441-54.535
			c-0.096-11.83-9.75-21.33-21.593-21.246c-11.83,0.096-21.342,9.763-21.246,21.592l0.441,54.536
			C216.046,88.401,225.616,97.876,237.366,97.876z"
                  />
                </g>
                <g>
                  <path
                    d="M21.919,217.116c-11.798,0-21.381,9.546-21.419,21.353c-0.037,11.831,9.523,21.45,21.354,21.487l54.535,0.171
			c0.023,0,0.045,0,0.068,0c11.798,0,21.382-9.547,21.419-21.354c0.038-11.83-9.523-21.45-21.353-21.487l-54.536-0.171
			C21.964,217.116,21.942,217.116,21.919,217.116z"
                  />
                </g>
                <g>
                  <path
                    d="M474.964,236.755c-0.096-11.771-9.666-21.247-21.416-21.247c-0.059,0-0.118,0-0.177,0.001l-54.535,0.441
			c-11.83,0.095-21.342,9.763-21.247,21.592c0.096,11.771,9.666,21.247,21.416,21.247c0.059,0,0.118,0,0.177,0l54.535-0.441
			C465.547,258.253,475.059,248.586,474.964,236.755z"
                  />
                </g>
                <g>
                  <path
                    d="M85.698,412.322c5.459,0,10.92-2.074,15.098-6.227l38.684-38.441c8.391-8.339,8.434-21.901,0.095-30.292
			c-8.338-8.393-21.901-8.435-30.292-0.096l-38.684,38.442c-8.391,8.338-8.434,21.9-0.095,30.292
			C74.691,410.214,80.194,412.322,85.698,412.322z"
                  />
                </g>
                <g>
                  <path
                    d="M366.784,138.46l38.25-38.875c8.297-8.433,8.188-21.994-0.244-30.292c-8.434-8.297-21.994-8.187-30.292,0.245
			l-38.251,38.875c-8.297,8.433-8.187,21.994,0.245,30.292c4.172,4.105,9.598,6.152,15.022,6.152
			C357.054,144.857,362.592,142.72,366.784,138.46z"
                  />
                </g>
                <g>
                  <path
                    d="M123.004,145.802c5.459,0,10.92-2.074,15.098-6.227c8.391-8.338,8.434-21.901,0.095-30.292L99.755,70.6
			c-8.338-8.392-21.901-8.434-30.292-0.095c-8.391,8.338-8.434,21.901-0.095,30.292l38.441,38.683
			C111.997,143.693,117.5,145.802,123.004,145.802z"
                  />
                </g>
                <g>
                  <path
                    d="M375.879,405.034c4.172,4.105,9.598,6.152,15.022,6.152c5.539,0,11.077-2.137,15.269-6.396
			c8.297-8.434,8.188-21.994-0.244-30.292l-38.875-38.25c-8.433-8.298-21.993-8.187-30.291,0.245
			c-8.297,8.433-8.188,21.994,0.245,30.291L375.879,405.034z"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div class="checkbox-wrapper-6">
            <input
              @change="changeTheme($event)"
              v-model="isCheckedTheme"
              class="tgl tgl-light input-check"
              id="cb1-6"
              type="checkbox"
            />
            <label class="tgl-btn" for="cb1-6"></label>
          </div>
          <div class="image_app">
            <svg
              class="svg_app"
              width="15px"
              height="15px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g id="Layer_1"></g>
              <g id="Layer_2">
                <g>
                  <path
                    d="M12,22C6.5,22,2,17.5,2,12c0-4.7,3.4-8.9,8-9.8c0.4-0.1,0.9,0.1,1.1,0.5c0.2,0.4,0.1,0.9-0.2,1.2C9.7,5.1,9,6.8,9,8.5    c0,3.6,2.9,6.5,6.5,6.5c1.7,0,3.4-0.7,4.6-1.9c0.3-0.3,0.8-0.4,1.2-0.2c0.4,0.2,0.6,0.6,0.5,1.1C20.9,18.6,16.7,22,12,22z     M7.6,5.3C5.4,6.8,4,9.2,4,12c0,4.4,3.6,8,8,8c2.8,0,5.2-1.4,6.7-3.6c-1,0.4-2.1,0.6-3.2,0.6C10.8,17,7,13.2,7,8.5    C7,7.4,7.2,6.3,7.6,5.3z"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <SideBarComp />
    <!-- ROUTER-VIEW -->
    <router-view></router-view>
    <!-- ROUTER-VIEW -->
    <!-- ВСПЛЫВАЮЩЕЕ ОКНО С УВЕДОМЛЕНИЯМИ -->
    <NotificationsMainComp
      :isModal="isModal"
      :unreadedNews="getUnreadNewsList"
      @close-news-window="closeNotificationsModal"
    />
  </div>
</template>

<script>
import NotificationsMainComp from "@/components/App/NotificationsMainComp.vue";
import {
  checkUserMe,

} from "@/api/api";
import SideBarComp from "@/components/App/SideBarComp.vue";
import LogoComp from "@/components/App/LogoComp.vue";
import "@/assets/css/style.css";
import "@/assets/css/main.css";
import "@/assets/css/checkbox_change_theme.css";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    SideBarComp,
    NotificationsMainComp,
    LogoComp,
  },
  data() {
    return {
      unreadedNews: [],

      isCheckedTheme: true,
      isDark: false,
      inter: null,

      isModal: false,

      THEME_LIGHT: "light",
      THEME_DARK: "dark",
    };
  },
  methods: {
    ...mapActions([
      "setUser",
      "setUserId",
      "startErrorFetchInterval",
      "startNewsFetching",
      "stopNewsFetching",
      "stopErrorFetchInterval",
    ]),

    async initializeUser(attempts = 3) {
      // Инициализация пользователя (Три попытки)
      try {
        const response = await checkUserMe();
        if (response.data.username) {
          this.setUser(response.data.username); //Добавляем юзера в хранилище
          this.setUserId(response.data.user_id); // Добавляем ID юзера в хранилище
        }
      } catch (error) {
        if (attempts > 0) {
          console.log(error.message);
          setTimeout(() => this.initializeUser(attempts - 1), 1000);
        } else {
          console.log(error.message);
        }
      }
    },
    checkUserAndRedirect() {
      // Если пользователь залогинен, редиректим его по соответствующему URL.
      if (this.getUser) {
        const validPath =
          this.currentPath && !["auth", "register"].includes(this.currentPath);
        this.$router.push(validPath ? `/${this.currentPath}` : `/devices`);
      }
    },
    startPageReloadInterval() {
      //Перезагружаем страницу один раз в час.
      this.inter = setInterval(() => this.$router.go(0), 3600000);
    },

    changeTheme(event) {
      this.isDark = event.target.checked;
      localStorage.setItem(
        "theme",
        this.isDark ? this.THEME_DARK : this.THEME_LIGHT
      );
    },
    closeNotificationsModal() {
      // Закрываем и открываем
      this.isModal = false;
    },
    openNotificationsModal() {
      // Закрываем и открываем
      this.isModal = true;
    },
  },

  computed: {
    ...mapGetters([
      "getUser", // Получаем пользователя
      "getUnreadNewsList", // Список непрочитанных новостей
      "unreadNewsListCount", // Счетчик непрочитанных новостей
      "allUnreadErrorsListCounter", // Счетчик непросмотренных ошибок
      "invitationsCounter" // Счетчик приглашений в организации
    ]),
    totalNotifCount() {
      //Общее кол-во непрочитанных новостей и непрочитанных ошибок
      return this.unreadNewsListCount + this.allUnreadErrorsListCounter + this.invitationsCounter;
    },
    currentPath() {
      return localStorage.getItem("currentPath");
    },
    themeClass() {
      return this.isDark ? "dark-theme" : "light-theme";
    },
  },
  async mounted() {
    const theme = localStorage.getItem("theme");
    this.isCheckedTheme = this.isDark = theme === this.THEME_DARK;
    this.startNewsFetching(); // Запрашиваем непрочитанные новости из хранилища
    this.startErrorFetchInterval(); // Запрашиваем непрочитанные ошибки из хранилища
  },
  watch: {
    // Следим за изменением длины списка непрочитанных новостей
    getUnreadNewsList(newList) {
      if (newList.length > 0) {
        this.openNotificationsModal();
      }
    },
  },

  async created() {
    await this.initializeUser(); // Инициализация пользователя
    this.checkUserAndRedirect(); // Если пользователь залогинен...
    this.startPageReloadInterval(); // Перезагружаем страницу один раз в час.
  },
  beforeUnmount() {
    clearInterval(this.inter); // Уничтожаем setInterval, который обновляет страницу один раз в час
    this.stopNewsFetching(); // Уничтожаем setInterval с запросом новостей
    this.stopErrorFetchInterval(); // Уничтожаем setInterval с запросом непрочитанных ошибок
  },
};
</script>

<style>
.bell {
  display: flex;
  margin: 0 15px;
  stroke: #ffffff;
  cursor: pointer;
}
.count_news {
  color: #ffffff;
}
.count_news:hover {
  text-decoration: underline;
}
.svg_app {
  stroke: none;
  fill: var(--content-bg-color);
}
.sidebar {
  display: flex;
  justify-content: center;
}
.theme_picker {
  margin-right: 50px;
  display: flex;
  align-items: center;
  transition: 0.2s;
}
.theme_picker:hover {
  opacity: 1;
}
.image_app {
  margin: 0 5px;
  display: flex;
  opacity: 0.5;
}

.main {
  grid-gap: 5px;
  height: 100vh;
  overflow-y: auto;
}

.main::-webkit-scrollbar {
  width: 0;
  background-color: none;
}

.main::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: none;
}

.main::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: none;
  margin-top: 15px;
  margin-bottom: 15px;
}

.header_wrapper {
  background-color: var(--content-bg-color);
  height: 85px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
}

.header {
  height: 70px;
  display: flex;
  justify-content: space-between;
  background-color: var(--header-color);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 12;
}

.header_hidden {
  display: none;
}
.space {
  height: 4rem;
}
@media screen and (max-width: 730px) {
  .header_wrapper {
    height: 45px;
  }
  .header {
    height: 45px;
  }
}
</style>
