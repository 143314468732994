//Модуль планирования - редактирование ТО
import {
  fetchPlannedMaintenance,
  updateTechInspect,
  createNewMaintenance,
} from "@/api/api";
import { convertDateForMaintenance } from "@/utils";

export default {
  //action высегда использует context.
  // У context есть метод commit.
  //при помощи деструктуризации из объекта context забираем ТОЛЬКО поле {commit}
  actions: {
    async fetchMaintenance({ commit }, payload) {
      const { currentOrganizationId, currentMonthStartDt, currentMonthEndDt } =
        payload;
      //Функция получает с сервера метки ТО.
      try {
        //Получаем запланырованные ТО текущей организации
        const res = await fetchPlannedMaintenance(
          currentOrganizationId, //id организации
          currentMonthStartDt, // Начало диапазона
          currentMonthEndDt // Конец диапазона
        );

        //меняем формат строки, что бы метки ТО отображались в календаре
        //2024-04-28T00:00:00 => Sun Apr 28 2024 00:00:00 GMT+0300 (Moscow Standard Time)
        // console.log(res.data)
        res.data.forEach((el) => {
          return (el.planned_date = convertDateForMaintenance(el.planned_date));
        });
        commit("SET_MAINTENANCE", res.data);
      } catch (error) {
        console.log(error);
      }
    },
    async updateMaintenance(_, payload) {
      try {
        const {
          maintenance_id,
          currentDate,
          currentDeviceId,
          currentMaintenanceType,
          actual_date,
          quarterly_value,
          monthly_value,
          yearly_value,
          currentContractor,
          conducted,
        } = payload;

        const response = await updateTechInspect(
          maintenance_id,
          currentDate,
          currentDeviceId,
          currentMaintenanceType,
          actual_date,
          quarterly_value,
          monthly_value,
          yearly_value,
          currentContractor,
          conducted
        );
        return response;
      } catch (error) {
        console.log(error);
      }
    },
    async createMaintenance(_, payload) {
      try {
        const {
          currentDeviceId,
          currentMaintenanceType,
          plannedDate,
          currentContractor,
          counterQuarterly,
          counterMonthly,
          counterAnnual,
        } = payload;
        const res = await createNewMaintenance(
          currentDeviceId,
          currentMaintenanceType,
          plannedDate,
          currentContractor,
          counterQuarterly,
          counterMonthly,
          counterAnnual
        );
        return res
      } catch (error) {
        console.log(error)
      }
      
    },
  },
  mutations: {
    // В мутации первый параметр - это всегда state, а второй параметр - это то,
    // что мы будем в него передавать  (в данном случае)
    // Экшн нужно вызывать в шаблоне, как метод.
    SET_MAINTENANCE(state, maintenanceList) {
      state.maintenanceList = maintenanceList;
    },
  },
  state: {
    maintenanceList: [],
  },
  getters: {
    maintenanceList: (state) => state.maintenanceList,
  },
};


