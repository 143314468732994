// Ф-ия расчитывает сколько минут устройство было неактивным.
// Если устройство было неактивным больше 10 мин, ф-ия возвращает false, после чего прогресс бар будет обнулен
// и на устройстве загорится серый индикатор
// Ф-ия импортируется в компоненты DeviceComp и MetricBox1
// Принимает строку формата - 2024-03-28T05:47:36.285560
export function calculateTimeDifference(dateString) {
  // Преобразуем lastActivity в объект Date
  const lastActivity = new Date(dateString);
  // Получаем текущее время
  const now = new Date();

  const offsetInMinutes = now.getTimezoneOffset(); // получаем разницу в во времени между часовыми поясами

  // Рассчитываем разницу в миллисекундах между временными метками
  let difference = now.getTime() - lastActivity.getTime();

  // Учитываем разницу в часовых поясах
  difference += offsetInMinutes * 60 * 1000;

  // Преобразуем разницу в минуты
  const minutes = Math.floor(difference / (1000 * 60));

  // Возвращаем разницу в минутах
  // если устройство неактивно больше 10 минут, то возвращаем false (серый индикатор - неактивна)
  return minutes < 10;
}

// Ф-ия расчитывает сколько минут устройство было неактивным.
// Если устройство было неактивным больше 10 мин, ф-ия возвращает false, после чего прогресс бар будет обнулен
// и на устройстве загорится серый индикатор
// Ф-ия импортируется в компоненты DeviceComp и MetricBox1
// Принимает строку формата - 2024-03-28T05:47:36.285560
export function calcTimeDiff_LessOneMinute(dateString) {
  // Преобразуем lastActivity в объект Date
  const lastActivity = new Date(dateString);
  // Получаем текущее время
  const now = new Date();

  const offsetInMinutes = now.getTimezoneOffset(); // получаем разницу в во времени между часовыми поясами

  // Рассчитываем разницу в миллисекундах между временными метками
  let difference = now.getTime() - lastActivity.getTime();

  // Учитываем разницу в часовых поясах
  difference += offsetInMinutes * 60 * 1000;

  // Преобразуем разницу в минуты
  const minutes = Math.floor(difference / (1000 * 60));

  // Возвращаем разницу в минутах
  // если устройство неактивно больше 10 минут, то возвращаем false (серый индикатор - неактивна)
  return minutes < 1;
}

// Форматируем строку С УЧЕТОМ ТАЙМЗОНЫ (часового пояса)
// 2024-03-28T05:47:36.285560 -> 28.03.2024, 09:02
// Ф-ия импортируется в компоненты ErrorTabs
export function convertDate(dateString) {
  const date = new Date(dateString);
  const now = new Date();
  const offsetInMinutes = now.getTimezoneOffset(); // Разница между вашим часовым поясом и UTC
  // Прибавляем или вычитаем разницу во времени к дате
  // Если разница во времени отрицательная то offset плюсуется (минус на минус)
  date.setMinutes(date.getMinutes() - offsetInMinutes);

  // Форматируем дату для вывода
  const year = date.getFullYear() % 100;
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedDateTime = `${day}.${
    month < 10 ? "0" : ""
  }${month}.${year} - ${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
  return formattedDateTime;
}

//Форматируем строку времени
// "Fri Apr 12 2024 00:00:00 GMT+0300 (Moscow Standard Time)" => "2024-04-11T08:45:09.514Z"
export function convertDateString(dateString) {
  // Создаем объект Date, передавая в конструктор исходную строку
  const date = new Date(dateString);

  // Получаем отдельные компоненты даты и времени
  const year = date.getFullYear(); // Год
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Месяц (не забываем прибавить 1, так как отсчет месяцев начинается с 0)
  const day = String(date.getDate()).padStart(2, "0"); // День
  const hours = String(date.getHours()).padStart(2, "0"); // Часы
  const minutes = String(date.getMinutes()).padStart(2, "0"); // Минуты
  const seconds = String(date.getSeconds()).padStart(2, "0"); // Секунды
  const milliseconds = String(date.getMilliseconds()).padStart(3, "0"); // Миллисекунды

  // Собираем новую строку в нужном формате
  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  return formattedDate;
}

// Форматирование строки
//2024-04-28T00:00:00 => Sun Apr 28 2024 00:00:00 GMT+0300 (Moscow Standard Time)
export function convertDateForMaintenance(dateString) {
  const date = new Date(dateString);
  date.setDate(date.getDate()); //
  date.setHours(0, 0, 0, 0); // Устанавливаем полночь

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dayOfWeek = daysOfWeek[date.getDay()];
  const month = months[date.getMonth()];
  // const dayOfMonth = String(date.getDate()).padStart(2, "0");
  const dayOfMonth = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0"); // Добавляем ведущий ноль
  const minutes = String(date.getMinutes()).padStart(2, "0"); // Добавляем ведущий ноль
  const seconds = String(date.getSeconds()).padStart(2, "0"); // Добавляем ведущий ноль

  return `${dayOfWeek} ${month} ${dayOfMonth} ${year} ${hours}:${minutes}:${seconds} GMT+0300 (Moscow Standard Time)`;
}

// Функция вычисляет время работы устройства (начало работы - конец работы)
// param-1 (string): first_activity: "2024-05-14T07:40:41.778467"
// param-2 (string): last_activity: "2024-05-14T08:35:14.993179"
// return 1ч 3мин / 58мин
export function timeDifference(firstTime, lastTime) {
  const diffInMilliseconds = new Date(lastTime) - new Date(firstTime);
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

  const hours = Math.floor(diffInMinutes / 60);
  const minutes = diffInMinutes % 60;
  if (hours > 0) {
    return `${hours}ч ${minutes}мин`;
  } else {
    return `${minutes}мин`;
  }
}

// Функция вычисляет время работы устройства (начало работы - конец работы)
// param-1 (string): first_activity: "2024-05-14T07:40:41.778467"
// param-2 (string): "2024-05-14T08:35:14.993179"
// return 158мин
export function timeWorkCounter(firstTime, lastTime) {
  const diffInMilliseconds = new Date(lastTime) - new Date(firstTime);
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  return diffInMinutes
}

// Функция переводит "минуты" в "часы-минуты" 
// param-1 (string): "68"

// return 1ч 8мин
export function hoursPerMinute(min) {
  const hours = Math.floor(min / 60);
  const minutes = min % 60;
  if (hours > 0) {
    return `${hours}ч ${minutes}м`;
  } else {
    return `${minutes}м`;
  }
}

// ServicesTab component
// Форматирование строки
//2024-04-28T00:00:00 => "23.04.2024"
export function dateStringFormat(dateString) {
  const date = new Date(dateString);

  // Получаем день, месяц и год из объекта Date
  const day = date.getDate();
  const month = date.getMonth() + 1; // Месяцы в JavaScript начинаются с 0, поэтому добавляем 1
  const year = date.getFullYear();

  // Формируем строку в нужном формате
  const formattedDateString = `${day < 10 ? "0" : ""}${day}.${
    month < 10 ? "0" : ""
  }${month}.${year}`;

  return formattedDateString; // Выведет "23.04.2024"
}
